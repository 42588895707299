<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb mb-0 p-0">
                        <li class="breadcrumb-item"><router-link to="/home"><i class="bx bx-home-alt"></i></router-link></li>
                        <li class="breadcrumb-item active" aria-current="page">AIQ Personas</li>
                    </ol>
                </nav>
            </div>
            <h6 class="mb-0 text-uppercase">AIQ Personas List</h6>
            <hr />
            <div class="card">
                <div class="card-body">
                    <div class="table-responsive">
                        <table id="example" class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Sl</th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Phone</th>
                                    <th>Platform</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, index) in dataList" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ data.firstName }}</td>
                                    <td>{{ data.lastName }}</td>
                                    <td>{{ data.mobilePhone }}</td>
                                    <td>{{ data.platform }}</td>
                                    <td>
                                        <div class="d-flex order-actions justify-content-center">
                                            <a href="javascript:void(0);" v-on:click="singlePersonas(data.contactID)" data-bs-toggle="modal" data-bs-target="#detailsPersonasModal" class="edit-button">
                                                <i class="fa fa-eye text-white"></i>
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>Sl</th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Phone</th>
                                    <th>Platform</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Add Modal -->
    <div class="modal fade" id="detailsPersonasModal" tabindex="-1" aria-labelledby="addModalLabel" aria-hidden="true">
        <form>
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title fw-bold" id="addModalLabel">
                            <i class="fa fa-eye me-2"></i> Details AIQ Personas
                        </h5>
                        <button type="button" id="addModalCloseButton" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="row ms-1">
                        <div class="modal-body col-md-6">
                            <div class="d-flex justift-content-center align-items-center gap-3">
                                <div class="text-nowrap fw-bold">
                                    <p>First Name:</p>
                                    <p>Last Name:</p>
                                    <p>Email:</p>
                                    <p>Platform:</p>
                                </div>
                                <div class="fs-10">
                                    <p class="">{{ singleData.firstName }}</p>
                                    <p>{{ singleData.lastName }}</p>
                                    <p>{{ singleData.email ? singleData.email : 'N/A'  }}</p>
                                    <p>{{ singleData.platform }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="modal-body col-md-6">
                            <table>
                                <tr>
                                    <td><strong>Loyalty Status:</strong>  </td>
                                    <td class="">{{ singleData.loyalty ? "Yes" : "No" }}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                            <i class="fadeIn animated bx bx-x"></i>Close
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <!-- Add Modal -->
</template>

<script src='../js/aiq-personas.js'></script>

<style scoped>
@import "../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
.edit-button {
  background-color: #17a00e;
}
.delete-button {
  background-color: #f41127;
}
.metaModal {
    color: black;
    cursor: pointer;
    text-decoration: none;
    display: block;
}
.metaModal:hover {
    cursor: pointer;
    color: #09f;
}
</style>
