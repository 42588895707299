import { authHeader } from '../../../auth';

export default {
    name: "Retailer",
    data() {
        return {
            dataList: [],
            setURL: this.$serverURL + this.$api.retailer.requestURL,
            singleData: {},
            previewImage: "",
            updateImage: ""
        }
    },
    async created() {
        document.title = "Jars - Retailer";
        this.dataList = await this.getDataList(this.setURL);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        editData(data) {
            this.singleData = data;
        },
        onImageChange(event) {
            this.updateImage = event.target.files[0];
            let input = this.$refs.image;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewImage = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        update: async function () {
            const formData = new FormData();
            formData.append("retailerImage", this.updateImage ? this.updateImage : this.singleData.retailerImage);
            formData.append("retailerMobileNumber", this.singleData.retailerMobileNumber);
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.retailer.requestURL + '/' + this.singleData.id,
                data: formData,
                headers: authHeader()
            };
            this.$swal.showLoading();
            await this.$axios(config).then(async (response) => {
                this.$swal.close();
                if (response.data.statusCode == 200) {
                    this.singleData = {};
                    this.$refs.image.value = "";
                    this.updateImage = "";
                    this.previewImage = "";
                    this.dataList = await this.getDataList(this.setURL);
                    document.getElementById("close-btn").click();
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
            }).catch(error => {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        }
    }
}