import { authHeader } from "../../../../auth";
import { GraphQLClient, gql } from "graphql-request";
export default {
    data() {
        return {
            productList: [],
            staticSiteMapUrl: this.$serverURL + this.$api.cms.siteMap.StaticSiteMapCms,
            generateSitemapUrl: this.$serverURL + this.$api.cms.siteMap.SitemapGenerate,
            categoryURL: this.$serverURL + this.$api.category.requestURL,
            categoryList: [],
            blogUrl: this.$serverURL + this.$api.blog.postURL,
            blogList: [],
            pressURL: this.$serverURL + this.$api.pressRelease.pressReleaseURL,
            pressList: [],
            dynamicSitemap: '',
            dynamicProductSitemap: '',
            dynamicBlogSitemap: '',
            staticSitemap: '',
            siteBaseUrl: 'https://www.jarscannabis.com',
            endpoint: this.$dutchieApi.DUTCHIE_PLUS_API_URL,
            token: this.$dutchieApi.DUTCHIE_PLUS_API_TOKEN
        }
    },
    async mounted() {
        document.title = "Jars - Site Map CMS";
    },
    async created() {
        await this.fetchAllProductsForSitemap();

        this.categoryList = await this.getDataList(this.categoryURL);
        await this.getBlogList();
        this.pressList = await this.getDataList(this.pressURL);
        const siteMapData = await this.getCmsData(this.staticSiteMapUrl);
        if(siteMapData?.data?.length) {
            this.staticSitemap = siteMapData?.data?.[0]?.siteMap;
        }

        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const dateString = `${year}-${month}-${day}`;

        const categorySlugList = this.categoryList.map(x => {
            return x.urlSlug ? x.urlSlug : this.createUrlSlug(x.categoryName);
        });

        const categorySiteMap = categorySlugList.map(slug => {
            return `<url>\n\t<loc>${this.siteBaseUrl}/category/${slug}</loc>\n\t<lastmod>${dateString}</lastmod>\n</url>`;
        });

        const blogSlugList = this.blogList.map(x => {
            return x.urlSlug ? x.urlSlug : this.createUrlSlug(x.categoryName);
        });

        const blogSiteMap = blogSlugList.map(slug => {
            return `<url>\n\t<loc>${this.siteBaseUrl}/blog/${slug}</loc>\n\t<lastmod>${dateString}</lastmod>\n</url>`;
        });

        const pressSlugList = this.pressList.map(x => {
            return x.urlSlug ? x.urlSlug : this.createUrlSlug(x.categoryName);
        });

        const pressMap = pressSlugList.map(slug => {
            return `<url>\n\t<loc>${this.siteBaseUrl}/press/${slug}</loc>\n\t<lastmod>${dateString}</lastmod>\n</url>`;
        });

        const productMap = this.productList.map(slug => {
            return `<url>\n\t<loc>${this.siteBaseUrl}/product-details/${slug}</loc>\n\t<lastmod>${dateString}</lastmod>\n</url>`;
        });        
        this.dynamicSitemap = categorySiteMap.join('\n');

        this.dynamicBlogSitemap += blogSiteMap.join('\n');
        this.dynamicBlogSitemap += pressMap.join('\n');

        this.dynamicProductSitemap = productMap.join('\n');
    },
    methods: {
        getBlogList: async function () {
            let config = {
                method: "GET",
                url: this.blogUrl,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.blogList = response.data.data;
                }
            }).catch(error => {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        async updateStaticSitemap(siteMapXml, blogSiteMapXml, productSiteMapXml) {
            try {
                if(!this.staticSitemap) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter static sitemap data!"
                    });
                } else {
                    const data = {
                        siteMap: this.staticSitemap,
                        siteMapXml,
                        blogSiteMapXml,
                        productSiteMapXml
                    };
                    const res = await this.createUpdateCMS(this.staticSiteMapUrl, data);
                    if (res.statusCode == 200) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: res.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                    }

                    const siteMapData = await this.getCmsData(this.staticSiteMapUrl);
                    if(siteMapData?.data?.length) {
                        this.staticSitemap = siteMapData?.data?.[0]?.siteMap;
                    }
                }
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! " + error
                });
            }
        },

        async generateSitemap() {
            const finalResult =
`<?xml version="1.0" encoding="UTF-8"?>
<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" 
        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" 
        xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9
            http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
${this.staticSitemap}
${this.dynamicSitemap}
</urlset>`;

            const blogSiteMapXml =
`<?xml version="1.0" encoding="UTF-8"?>
<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" 
        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" 
        xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9
            http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
${this.dynamicBlogSitemap}
</urlset>`;

            const productSiteMapXml =
`<?xml version="1.0" encoding="UTF-8"?>
<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" 
        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" 
        xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9
            http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
${this.dynamicProductSitemap}
</urlset>`;
            await this.updateStaticSitemap(finalResult, blogSiteMapXml, productSiteMapXml);
        },

        getGraphQLClient() {
            try {
                const graphQLClient = new GraphQLClient(this.endpoint, {
                    headers: {
                        authorization: `Bearer ${this.token}`
                    }
                });
                return graphQLClient;
            } catch (error) {
                console.log(error);
                return error;
            }
        },
        fetchAllRetailers() {
            const query = gql`
                fragment addressFragment on AddressObject {
                    line1
                    line2
                    city
                    postalCode
                    state
                    country
                }

                fragment bannerColorsFragment on BannerColorConfiguration {
                    background
                    border
                    color
                    id
                }

                fragment deliverySettingsFragment on DeliverySettings {
                    afterHoursOrderingForDelivery
                    afterHoursOrderingForPickup
                    deliveryArea
                    deliveryFee
                    deliveryMinimum
                    disablePurchaseLimits
                    limitPerCustomer
                    pickupMinimum
                    scheduledOrderingForDelivery
                    scheduledOrderingForPickup
                }

                fragment hoursDayFragment on HoursDay {
                    active
                    start
                    end
                }

                fragment hoursFragment on Hours {
                    Sunday {
                    ...hoursDayFragment
                    }
                    Monday {
                    ...hoursDayFragment
                    }
                    Tuesday {
                    ...hoursDayFragment
                    }
                    Wednesday {
                    ...hoursDayFragment
                    }
                    Thursday {
                    ...hoursDayFragment
                    }
                    Friday {
                    ...hoursDayFragment
                    }
                    Saturday {
                    ...hoursDayFragment
                    }
                }

                fragment retailerFragment on Retailer {
                    address
                    addressObject {
                        ...addressFragment
                    }
                    banner {
                        colors {
                            ...bannerColorsFragment
                        }
                        html
                    }
                    coordinates {
                        latitude
                        longitude
                    }
                    deliverySettings {
                        ...deliverySettingsFragment
                    }
                    
                    fulfillmentOptions {
                        curbsidePickup
                        delivery
                        driveThruPickup
                        pickup
                    }
                    hours {
                        delivery {
                            ...hoursFragment
                        }
                        pickup {
                            ...hoursFragment
                        }
                        regular {
                            ...hoursFragment
                        }
                        special {
                            startDate
                            endDate
                            hoursPerDay {
                                date
                                deliveryHours {
                                    ...hoursDayFragment
                                }
                                pickupHours {
                                    ...hoursDayFragment
                                }
                            }
                            name
                        }
                    }
                    id
                    menuTypes
                    name
                    paymentMethodsByOrderTypes {
                        orderType
                        paymentMethods
                    }
                    settings {
                        menuWeights
                    }
                }

                # Return retailer data for all stores
                query RetailersQuery {
                    retailers {
                        ...retailerFragment
                    }
                }
            `;
            return query;
        },
        fetchProductsCount() {
            const query = gql`
            query MenuQuery(
                $retailerId: ID!
            ) {
                menu(
                    retailerId: $retailerId
                ) {
                    productsCount
                }
            }`;
            return query;
        },
        fetchAllProducts() {
            const query = gql`
            fragment productFragment on Product {
                brand {
                    id
                    name
                }
                image
                id
                name
                slug
                category
                subcategory
                strainType
                effects
                variants {
                    id
                    option
                    priceMed
                    priceRec
                    specialPriceMed
                    specialPriceRec
                    quantity
                }
            }
            query FilterProducts(
                $retailerId: ID!
                $pagination: Pagination,
            ) {
                menu(
                    retailerId: $retailerId
                    pagination: $pagination,
                ) {
                    products {
                        ...productFragment
                    }
                }
            }
            `;
            return query;
        },
        async fetchAllProductsForSitemap() {
            try {
                console.log(">>>Run Fetch All Products For Sitemap<<<");

                // Get GraphQL client
                const graphQLClient = this.getGraphQLClient();

                // Fetch all retailers
                const query = this.fetchAllRetailers();
                const data = await graphQLClient.request(query);
                const retailers = data.retailers;

                // Check if retailers exist
                if (retailers.length > 0) {
                    // Create an array of promises for fetching product data
                    console.log("Start Fetching product count Parallel =", new Date());
                    const fetchProductsCountObject = this.fetchProductsCount();
                    const retailerListPromises = retailers.map((retailer) =>
                        graphQLClient.request(fetchProductsCountObject, { retailerId: retailer.id })
                            .then(response => {
                                if (response && response.menu && response.menu.productsCount != null) {
                                    return {
                                        retailerId: retailer.id,
                                        retailerName: retailer.name,
                                        pagination: {
                                            offset: 0,
                                            limit: response.menu.productsCount ? parseInt(response.menu.productsCount) : 0
                                        }
                                    };
                                }
                            })
                    );

                    let allRetailerProductCount = await Promise.all(retailerListPromises);
                    allRetailerProductCount = allRetailerProductCount.filter((data) => data.pagination.limit > 0);
                    console.log("End Fetching product count Parallel =", new Date());

                    console.log("Start Fetching product Parallel =", new Date());
                    const fetchProductsQuery = this.fetchAllProducts();
                    const fetchProductPromises = allRetailerProductCount.map(variable =>
                        graphQLClient.request(fetchProductsQuery, variable).then(response => {
                            if (response && response.menu && response.menu.products) {
                                return {
                                    retailerId: variable.retailerId,
                                    retailerName: variable.retailerName,
                                    productData: response
                                };
                            }
                        })
                    );
                    console.log("End Fetching product Parallel =", new Date());

                    const allProductData = await Promise.all(fetchProductPromises);
                    const products = [];
                    for (const data of allProductData) {
                        const product = data.productData.menu.products.map(x => x.slug);
                        products.push(...product);
                    }
                    console.log(">>>End Fetch All Products For Sitemap<<<");
                    // console.table(products.length);
                    this.productList = products;
                } else {
                    console.log("Retailers Not Found!");
                }
            } catch (e) {
                console.log(e);
            }
        }
    }
}