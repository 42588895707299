<template>
  <div class="sidebar-wrapper" data-simplebar="true">
    <div class="sidebar-header">
      <div>
        <img src="../../../../public/core/assets/images/logo-icon.png" class="logo-icon" alt="logo icon" />
      </div>
      <div>
        <h4 class="logo-text">JARS AZ</h4>
      </div>
      <div class="toggle-icon ms-auto">
        <i class="bx bx-arrow-to-left"></i>
      </div>
    </div>
    <ul class="metismenu" id="menu">
      <li>
        <a href="javascript:void(0)" @click="$router.push('/home')">
          <div class="parent-icon">
            <i class="bx bxs-dashboard"></i>
          </div>
          <div class="menu-title">Dashboard</div>
        </a>
      </li>
      <!-- <li>
        <a href="javascript:void(0)" @click="$router.push('/store')">
          <div class="parent-icon">
            <i class="bx bxs-store"></i>
          </div>
          <div class="menu-title">Locations</div>
        </a>
      </li> -->
      <li>
        <a href="#" class="has-arrow">
          <div class="parent-icon">
            <i class="bx bxs-up-arrow"></i>
          </div>
          <div class="menu-title">Data</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/retailer-aiq')">
              <i class="bx bx-right-arrow-alt"></i>Retailer AIQ Service
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/feedback')">
              <i class="bx bx-right-arrow-alt"></i>Feedback
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/product-review')">
              <i class="bx bx-right-arrow-alt"></i>Product Review
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/contact')">
              <i class="bx bx-right-arrow-alt"></i>Contact Request
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/weekly-ad-signups')">
              <i class="bx bx-right-arrow-alt"></i>Weekly Ad Signups
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/retailer-iheartjane')">
              <i class="bx bx-right-arrow-alt"></i>iHeart Jane Retailer Service
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/career')">
              <i class="bx bx-right-arrow-alt"></i>Career List
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/career-applicant')">
              <i class="bx bx-right-arrow-alt"></i>Career Applicant
            </a>
          </li>
        </ul>
      </li>
      <li>
        <a href="#" class="has-arrow">
          <div class="parent-icon">
            <i class="bx bx-cog"></i>
          </div>
          <div class="menu-title">System Settings</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/state-wise-contact-reason')">
              <i class="bx bx-right-arrow-alt"></i>Contact Reason
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/synchronize')">
              <i class="bx bx-right-arrow-alt"></i>Synchronize
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/cart-counter-cms')">
              <i class="bx bx-right-arrow-alt"></i>Cart Counter
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/offer-page-badge-toggle-cms')">
              <i class="bx bx-right-arrow-alt"></i>Offer Badge Toggle Cms
            </a>
          </li>
        </ul>
      </li>

      <li>
        <a href="#" class="has-arrow">
          <div class="parent-icon">
            <i class="fadeIn animated bx bxs-user-rectangle"></i>
          </div>
          <div class="menu-title">User</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/admins')">
              <i class="bx bx-right-arrow-alt"></i>Admins
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/customers')">
              <i class="bx bx-right-arrow-alt"></i>Customers
            </a>
          </li>
        </ul>
      </li>


      <li>
        <a href="#" class="has-arrow">
          <div class="parent-icon">
            <i class="fa-solid fa-list-check"></i>
          </div>
          <div class="menu-title">SEO</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/site-page-title-cms')">
              <i class="bx bx-right-arrow-alt"></i>Site Page Title CMS
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/site-page-meta-cms')">
              <i class="bx bx-right-arrow-alt"></i>Site Page Meta CMS
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/site-map')">
              <i class="bx bx-right-arrow-alt"></i>Site Map
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/product-meta-mapping')">
              <i class="bx bx-right-arrow-alt"></i>Product Details Page Meta
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/robot-txt-cms')">
              <i class="bx bx-right-arrow-alt"></i>Robot Text
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/page-schema')">
              <i class="bx bx-right-arrow-alt"></i>Page Schema
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/delivery-pages')">
              <i class="bx bx-right-arrow-alt"></i>Delivery Pages
            </a>
          </li>
        </ul>
      </li>
      <li>
        <a href="#" class="has-arrow">
          <div class="parent-icon">
            <i class="bx bxs-cog"></i>
          </div>
          <div class="menu-title">CMS Setting</div>
        </a>
        <ul>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-home-alt"></i>
              </div>
              <div class="menu-title">Home</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/home-slider')">
                  <!-- <a href="javascript:void(0)" @click="$router.push('/state-list')"> -->
                  <i class="bx bx-right-arrow-alt"></i>Home Slider
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/home-page-sub-nav')">
                  <i class="bx bx-right-arrow-alt"></i>Home Page Sub Nav
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/home-page-mobile-category-cms')">
                  <!-- <a href="javascript:void(0)" @click="$router.push('/state-list')"> -->
                  <i class="bx bx-right-arrow-alt"></i>Home Page Mobile Category
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/section-two-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Section Two
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/section-three-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Section Three
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/section-four-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Section Four
                </a>
              </li>
              <!-- <li>
                <a href="javascript:void(0)" @click="$router.push('/section-four-slider-states')">
                  <i class="bx bx-right-arrow-alt"></i>Section Four Slider
                </a>
              </li>-->
              <li>
                <a href="javascript:void(0)" @click="$router.push('/common-banner-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Common Banner
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/new-drop-banner-cms')">
                  <i class="bx bx-right-arrow-alt"></i>New Drop Banner
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/section-five-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Section Five
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/section-seven-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Section Seven
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/section-eight-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Section Eight
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/section-nine-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Section Nine
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/instagram-section-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Instagram CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bxs-pen"></i>
              </div>
              <div class="menu-title">Article</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/blog-post')">
                  <i class="bx bx-right-arrow-alt"></i>Article Post
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/article-category')">
                  <i class="bx bx-right-arrow-alt"></i>Article Category
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/article-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Article Page CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/article-details-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Article Details CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bxs-news"></i>
              </div>
              <div class="menu-title">Press Release</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/press-release')">
                  <i class="bx bx-right-arrow-alt"></i>Press Release
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/press-release-category')">
                  <i class="bx bx-right-arrow-alt"></i>Press Release Category
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/press-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Press Release CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-copy-alt"></i>
              </div>
              <div class="menu-title">Header Footer</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/header-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Header CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/footer-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Footer CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/header-banner-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Header Banner CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/privacy-policy-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Privacy Policy
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/terms-of-service-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Terms of Service
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/cookies-settings-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Cookies Settings
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-info-circle"></i>
              </div>
              <div class="menu-title">About</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/about-page-header-section-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Header Section CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/about-page-section-one-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Section One CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/about-page-section-two-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Section Two CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/about-page-section-three-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Section Three CMS
                </a>
              </li>
              <!-- <li>
                                <a href="javascript:void(0)" @click="$router.push('/about-page-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>Page CMS
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" @click="$router.push('/about-section')">
                                    <i class="bx bx-right-arrow-alt"></i>Page Section Two
                                </a>
              </li>-->
            </ul>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-briefcase-alt-2"></i>
              </div>
              <div class="menu-title">Career</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/career-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Page CMS
                </a>
              </li>

            </ul>
          </li>
          <!-- <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-select-multiple"></i>
              </div>
              <div class="menu-title">Shop</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/shop-page-banner-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Shop Banner Cms
                </a>
              </li>
            </ul>
          </li> -->

          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-select-multiple"></i>
              </div>
              <div class="menu-title">Deals</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/deals-dropdown-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Deals Dropdown CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/deals-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Deals Page CMS
                </a>
              </li>
            </ul>
          </li>

          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-select-multiple"></i>
              </div>
              <div class="menu-title">Quiz</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/quiz')">
                  <i class="bx bx-right-arrow-alt"></i>Quiz List
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/quiz-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Page CMS
                </a>
              </li>
            </ul>
          </li>

          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-cart-alt"></i>
              </div>
              <div class="menu-title">Cart</div>
            </a>
            <ul>
              <!-- <li>
                <a href="javascript:void(0)" @click="$router.push('/high-deals-cms')">
                  <i class="bx bx-right-arrow-alt"></i>High Deals
                </a>
              </li> -->
              <li>
                <a href="javascript:void(0)" @click="$router.push('/cart-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Page CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/cart-counter-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Cart Counter
                </a>
              </li>
            </ul>
          </li>

          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-user-circle"></i>
              </div>
              <div class="menu-title">Auth</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/login-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Login Page
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/register-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Register Page
                </a>
              </li>
            </ul>
          </li>

          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-spreadsheet"></i>
              </div>
              <div class="menu-title">Product Details</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/how-to-enjoy-cms')">
                  <i class="bx bx-right-arrow-alt"></i>How to Enjoy CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/related-products-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Related Products CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/discover-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Discover CMS
                </a>
              </li>
              <!-- <li>
                                <a href="javascript:void(0)" @click="$router.push('/reserve-time-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>Reserve Time CMS
                                </a>
              </li>-->
            </ul>
          </li>

          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="fadeIn animated bx bxs-purchase-tag"></i>
              </div>
              <div class="menu-title">Weekly Ad</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/weekly-ad-tags')">
                  <i class="bx bx-right-arrow-alt"></i>Tags
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/weekly-ad-state-list')">
                  <i class="bx bx-right-arrow-alt"></i>Weekly Ads List
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/weekly-ad-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Weekly Ads CMS
                </a>
              </li>
            </ul>
          </li>

          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class='bx bxs-search'></i>
              </div>
              <div class="menu-title">Popup Modules</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/offer-pop-up-state-list')">
                  <i class="bx bx-right-arrow-alt"></i>Offer Popup Module
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/newsletter-pop-up-state-list')">
                  <i class="bx bx-right-arrow-alt"></i>Newsletter Popup Module
                </a>
              </li>

            </ul>
          </li>

          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-question-mark"></i>
              </div>
              <div class="menu-title">FAQ</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/faq')">
                  <i class="bx bx-right-arrow-alt"></i>FAQ
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/faq-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Page CMS
                </a>
              </li>
            </ul>
          </li>


          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-shopping-bag"></i>
              </div>
              <div class="menu-title">JARS+</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/section-one-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Section One CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/steps-section-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Steps Section CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/benefits-section-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Benefits Section CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/benefits-modal')">
                  <i class="bx bx-right-arrow-alt"></i>Benefits Modal
                </a>
              </li>
              <!-- <li>
                                <a href="javascript:void(0)" @click="$router.push('/section-two-top')">
                                    <i class="bx bx-right-arrow-alt"></i>Section Two Top
                                </a>
              </li>-->
              <!-- <li>
                                <a href="javascript:void(0)" @click="$router.push('/section-two-bottom')">
                                    <i class="bx bx-right-arrow-alt"></i>Section Two Bottom
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" @click="$router.push('/section-three')">
                                    <i class="bx bx-right-arrow-alt"></i>Section Three
                                </a>
              </li>-->
            </ul>
          </li>

          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bxs-category-alt"></i>
              </div>
              <div class="menu-title">Category Service</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/category')">
                  <i class="bx bx-right-arrow-alt"></i>All
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/Category-states')">
                  <i class="bx bx-right-arrow-alt"></i>State Wise
                </a>
              </li>
            </ul>
          </li>

          <li>
            <a href="javascript:void(0)" @click="$router.push('/featured-brand')">
              <i class="bx bx-right-arrow-alt"></i> Featured Brands
            </a>
          </li>

          <li>
            <a href="javascript:void(0)" @click="$router.push('/user-page-banner-cms')">
              <i class="bx bx-right-arrow-alt"></i>User Page CMS
            </a>
          </li>

          <li>
            <a href="javascript:void(0)" @click="$router.push('/service-type-cms')">
              <i class="bx bx-right-arrow-alt"></i>Service Type CMS
            </a>
          </li>

          <li>
            <a href="javascript:void(0)" @click="$router.push('/age-verification-page-cms')">
              <i class="bx bx-right-arrow-alt"></i>Age Verification CMS
            </a>
          </li>

          <li>
            <a href="javascript:void(0)" @click="$router.push('/weekly-ad-signups')">
              <i class="bx bx-right-arrow-alt"></i>Weekly Ad Signups
            </a>
          </li>

          <li>
            <a href="javascript:void(0)" @click="$router.push('/landing-page-cms')">
              <i class="bx bx-right-arrow-alt"></i>Landing Page CMS
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/pickup-delivery-state-list')">
              <i class="bx bx-right-arrow-alt"></i>Pickup Delivery CMS
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/contact-page-store-list')">
              <i class="bx bx-right-arrow-alt"></i>Contact Page CMS
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/social-link')">
              <i class="bx bx-right-arrow-alt"></i>Social Link
            </a>
          </li>
        </ul>
      </li>
      <li>
        <a href="#" class="has-arrow">
          <div class="parent-icon">
            <i class="bx bxs-component"></i>
          </div>
          <div class="menu-title">Component UI</div>
        </a>
        <ul>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-book-content"></i>
              </div>
              <div class="menu-title">Article</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/article-page-component-ui')">
                  <i class="bx bx-right-arrow-alt"></i>Article Page
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/article-details-page-component-ui')">
                  <i class="bx bx-right-arrow-alt"></i>Article Details Page
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-user-circle"></i>
              </div>
              <div class="menu-title">Auth</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/login-page-component-ui')">
                  <i class="bx bx-right-arrow-alt"></i>Login Page
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/register-page-component-ui')">
                  <i class="bx bx-right-arrow-alt"></i>Register Page
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/about-page-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>About Page
            </a>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-briefcase-alt-2"></i>
              </div>
              <div class="menu-title">Career</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/career-page-component-ui')">
                  <i class="bx bx-right-arrow-alt"></i>Career Page
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/cookies-settings-page-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>Cookies Settings Page
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/contact-page-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>Contact Page
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/deals-page-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>Deals Page
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/faq-page-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>Faq Page
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/home-page-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>Home Page
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/jarsplus-page-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>Jars Plus Page
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/landing-page-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>Landing Page
            </a>
          </li>

          <li>
            <a href="javascript:void(0)" @click="$router.push('/newsletter-popup-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>Newsletter Popup
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/offer-popup-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>Offer Popup
            </a>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-news"></i>
              </div>
              <div class="menu-title">Press</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/press-page-component-ui')">
                  <i class="bx bx-right-arrow-alt"></i>Press Page
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/press-details-page-component-ui')">
                  <i class="bx bx-right-arrow-alt"></i>Press Details Page
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-news"></i>
              </div>
              <div class="menu-title">Product Details</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/discover-highlights-component-ui')">
                  <i class="bx bx-right-arrow-alt"></i>Discover Highlights
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/privacy-policy-page-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>Privacy Policy Page
            </a>
          </li>

          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-briefcase-alt-2"></i>
              </div>
              <div class="menu-title">Shop</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/shop-page-component-ui')">
                  <i class="bx bx-right-arrow-alt"></i>Shop Banner
                </a>
              </li>
            </ul>
          </li>



          <li>
            <a href="javascript:void(0)" @click="$router.push('/terms-condition-page-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>Terms Condition Page
            </a>
          </li>



          <li>
            <a href="javascript:void(0)" @click="$router.push('/top-ticker-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>Top Ticker
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/user-page-banner-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>User Page Banner
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/weekly-ad-page-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>Weekly Ad Page
            </a>
          </li>

        </ul>
      </li>
    </ul>
  </div>
</template>