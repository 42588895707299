<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb mb-0 p-0">
                        <li class="breadcrumb-item"><router-link to="/home"><i class="bx bx-home-alt"></i></router-link></li>
                        <li class="breadcrumb-item active" aria-current="page">Store</li>
                    </ol>
                </nav>
                <div class="ms-auto" v-if="Object.values(cmsData).length > 0">
                    <div class="btn-group">
                        <button type="button" @click="deleteContent()" class="btn btn-warning m-1">
                            <i class='bx bx-code'></i>Select None
                        </button>
                    </div>
                </div>
            </div>
            <h6 class="mb-0 text-uppercase">Store List</h6>
            <hr/>
            <div class="card">
                <div class="card-body">
                    <div class="table-responsive">
                        <table id="example" class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Sl</th>
                                    <th>Name</th>
                                    <th>Location</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, index) in dataList" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ data.name }}</td>
                                    <td>{{ data.address }}</td>
                                    <td>
                                        <button v-if="data.deliverySettings.afterHoursOrderingForDelivery == true || data.deliverySettings.afterHoursOrderingForPickup == true" type="button" class="btn btn-primary" :disabled="data.id == cmsData.retailerId" @click="update(data)">
                                            {{ data.id == cmsData.retailerId ? "Selected" : "Select" }}
                                        </button>
                                        <button v-if="data.deliverySettings.afterHoursOrderingForDelivery == false && data.deliverySettings.afterHoursOrderingForPickup == false" type="button" class="btn btn-warning" disabled>
                                            Out of service
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>Sl</th>
                                    <th>Name</th>
                                    <th>Location</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src='../js/store.js'></script>

<style scoped>
@import "../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
.edit-button {
  background-color: #17a00e;
}
.delete-button {
  background-color: #f41127;
}
.metaModal {
    color: black;
    cursor: pointer;
    text-decoration: none;
    display: block;
}
.metaModal:hover {
    cursor: pointer;
    color: #09f;
}
</style>
