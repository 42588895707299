<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb mb-0 p-0">
                        <li class="breadcrumb-item">
                            <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Shop Page
                        </li>
                    </ol>
                </nav>
                <div class="ms-auto">
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <h6 class="mb-0">Shop Page Custom Filter Section</h6>
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-md-6 mx-auto">
                    <div class="card">
                        <div class="card-body">
                            <div class="text-center">
                                <div class="border border-3 p-4 rounded">
                                    <div class="d-flex justify-content-center">
                                        <img style=" height: 350px;" src="../../../../public/core/assets/images/shop-page-filter.png">
                                    </div>
                                    <h6 class="text-center pt-4"><b>Jane Custom Key: Bundles</b></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 mx-auto">
                    <div class="card">
                        <div class="card-body">
                            <div class="text-center">
                                <div class="border border-3 p-4 rounded">
                                    <div class="d-flex justify-content-center">
                                        <img style=" height: 350px;" src="../../../../public/core/assets/images/shop-page-filter.png">
                                    </div>
                                    <h6 class="text-center pt-4"><b>Jane Custom Key: Current Promos</b></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 mx-auto">
                    <div class="card">
                        <div class="card-body">
                            <div class="text-center">
                                <div class="border border-3 p-4 rounded">
                                    <div class="d-flex justify-content-center">
                                        <img style=" height: 350px;" src="../../../../public/core/assets/images/shop-page-filter.png">
                                    </div>
                                    <h6 class="text-center pt-4"><b>Jane Custom Key: Save with JARS</b></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 mx-auto">
                    <div class="card">
                        <div class="card-body">
                            <div class="text-center">
                                <div class="border border-3 p-4 rounded">
                                    <div class="d-flex justify-content-center">
                                        <img style=" height: 350px;" src="../../../../public/core/assets/images/shop-page-filter.png">
                                    </div>
                                    <h6 class="text-center pt-4"><b>Jane Custom Key: Weekly Deals</b></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/shop-page.js"></script>

<style scoped>
.edit-button {
    background-color: #17a00e;
}

.delete-button {
    background-color: #f41127;
}
</style>