import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { authHeader } from '../../../auth';
import Editor from '@tinymce/tinymce-vue';

export default {
    name: "Retailer",
    components: {
        Editor
    },
    data() {
        return {
            dataList: [],
            setURL: this.$serverURL + this.$api.retailer.requestIHeartJaneURL,
            singleData: {
                id: "",
                retailerName: "",
                email: "",
                about: "",
                todaysSpecial: "",
                jarsRewards: "",
                referAFriend: "",
                pageTitle: "",
                featuredImage: "",
                retailerImage: [],
                canonical: "",
                schema: ""
            },
            previewImage: "",
            featuredImage: null,
            updateImage: "",
            storeImageList: [],
            deletedImage: [],
            previewStoreImages: [],
            disableButton: false,
            editor: ClassicEditor,
            editorConfig: {
                ckfinder: {
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL
                }
            },
            tempSingleData: {},
        }
    },
    async created() {
        document.title = "Jars - Retailer";
        this.dataList = await this.getDataList(this.setURL);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        checkNullString(data) {
            return (data != "null") ? true : false;
        },
        async updateDataToSingleData() {
            this.previewImage = "";
            this.updateImage = "";
            this.$refs.image.value = "";
            this.$refs.storeImages.value = null;
            this.previewStoreImages = [];
            this.storeImageList = [];
            this.deletedImage = [];
            this.singleData = {
                id: this.tempSingleData.id,
                retailerName: (this.tempSingleData.retailerName && this.checkNullString(this.tempSingleData.retailerName)) ? this.tempSingleData.retailerName : '',
                email: (this.tempSingleData.email && this.checkNullString(this.tempSingleData.email)) ? this.tempSingleData.email : '',
                about: (this.tempSingleData.about && this.checkNullString(this.tempSingleData.about)) ? this.tempSingleData.about : '',
                todaysSpecial: (this.tempSingleData.todaysSpecial && this.checkNullString(this.tempSingleData.todaysSpecial)) ? this.tempSingleData.todaysSpecial : '',
                jarsRewards: (this.tempSingleData.jarsRewards && this.checkNullString(this.tempSingleData.jarsRewards)) ? this.tempSingleData.jarsRewards : '',
                referAFriend: (this.tempSingleData.referAFriend && this.checkNullString(this.tempSingleData.referAFriend)) ? this.tempSingleData.referAFriend : '',
                pageTitle: (this.tempSingleData.pageTitle && this.checkNullString(this.tempSingleData.pageTitle)) ? this.tempSingleData.pageTitle : '',
                schema: (this.tempSingleData.schema && this.checkNullString(this.tempSingleData.schema)) ? this.tempSingleData.schema : '',
                canonical: (this.tempSingleData.canonical && this.checkNullString(this.tempSingleData.canonical)) ? this.tempSingleData.canonical : '',
                featuredImage: this.tempSingleData.featuredImage ? this.tempSingleData.featuredImage : '',
                retailerImage: this.tempSingleData.retailerImage.length > 0 ? this.tempSingleData.retailerImage : [],
            };
            console.log('first', this.singleData)
            if (this.singleData.retailerImage.length > 0) {
                this.storeImageList = this.singleData.retailerImage.map(el => {
                    return el.image;
                })
                this.previewStoreImages = this.singleData.retailerImage.map(el => {
                    return el.image;
                })
            }
            // this.previewStoreImages = this.singleData.retailerImage.length > 0 ? this.singleData.retailerImage : [];
            // this.storeImageList = this.singleData.retailerImage.length > 0 ? this.singleData.retailerImage : [];
            if (this.previewStoreImages.length > 3) {
                this.disableButton = true;
            }
            else {
                this.disableButton = false;
            }
        },
        editData(data) {
            this.tempSingleData = data;
        },
        onImageChange(event) {
            this.updateImage = event.target.files[0];
            let input = this.$refs.image;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewImage = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        update: async function () {
            const formData = new FormData();
            formData.append("featuredImage", (this.updateImage && this.updateImage != null) ? this.updateImage : this.singleData.featuredImage);
            formData.append("retailerMobileNumber", this.singleData.retailerMobileNumber);
            formData.append("email", this.singleData.email);
            formData.append("about", this.singleData.about);
            formData.append("jarsRewards", this.singleData.jarsRewards);
            formData.append("referAFriend", this.singleData.referAFriend);
            formData.append("pageTitle", this.singleData.pageTitle);
            formData.append("schema", this.singleData.schema);
            formData.append("canonical", this.singleData.canonical);
            formData.append("todaysSpecial", this.singleData.todaysSpecial ? this.singleData.todaysSpecial : "TODAY’S SPECIALS");
            this.storeImageList.map((el) => {
                if (typeof el !== "string") {
                    formData.append("retailerImage", el);
                }
            });
            this.deletedImage.map((el) => {
                formData.append("deletedImage", el);
            });
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.retailer.requestIHeartJaneURL + '/' + this.singleData.id,
                data: formData,
                headers: authHeader()
            };
            this.$swal.showLoading();
            await this.$axios(config).then(async (response) => {
                this.$swal.close();
                if (response.data.statusCode == 200) {
                    setTimeout(async () => {
                        this.dataList = await this.getDataList(this.setURL);
                    }, 1500)
                    document.getElementById("close-btn").click();
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
            }).catch(error => {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        uploadStoreImages() {
            this.storeImageList.push(this.$refs.storeImages.files[0]);
            if (this.storeImageList.length > 3) {
                this.disableButton = true;
            }
            let input = this.$refs.storeImages
            let file = input.files
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewStoreImages.push(e.target.result)
                }
                reader.readAsDataURL(file[0])
            }
            this.$refs.storeImages.value = null;
        },
        removeUploadedStoreImage(index) {
            this.previewStoreImages.splice(index, 1);
            if (typeof this.storeImageList[index] === "string") {
                if (this.storeImageList[index].startsWith("https")) {
                    this.deletedImage.push(this.storeImageList[index]);
                }
            }
            this.storeImageList.splice(index, 1);
            if (this.storeImageList.length > 3) {
                this.disableButton = true;
            } else {
                this.disableButton = false;
            }
        },
    },
    watch: {
        tempSingleData: {
            deep: true,
            handler(newVal, oldVal) {
                if (newVal?.retailerId != oldVal?.retailerId) {
                    this.updateDataToSingleData();
                }
            }
        }
    }
}