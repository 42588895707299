import { authHeader } from '../../../auth';

export default {
    name: "EditCareerDepartment",
    data() {
        return {
            editData: [],
            name: '',
            logoFile: '',
            previewLogo: '',
            logo: '',
            id: ''
        }
    },
    async created() {
        document.title = "JARS - Edit Career Department";
    },
    mounted() {
        if(this.$route.params.id != undefined) {
            this.id = this.$route.params.id;
        }
        this.getData(this.id);
    },
    methods: {
        getData: async function(id) {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.careerDepartment.requestURL + '/' + id,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.editData = response.data.data[0];
                    this.name = this.editData.name;
                    this.logo = this.editData.logo;
                }
            }).catch(error => {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        onLogoChange(event) {
            this.logoFile = event.target.files[0];
            let input = this.$refs.logo;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewLogo = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        update: async function() {
            let token = localStorage.getItem('token');
            const formData = new FormData();
            formData.append("name", this.name);
            formData.append("logo", this.logoFile ? this.logoFile : this.logo);
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.careerDepartment.requestURL + '/' + this.id,
                data: formData,
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "multipart/form-data"
                }
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                this.previewLogo = '';
                this.logoFile = '';
                this.$refs.logo.value = null;
                this.$router.push("/career-department");
            }).catch(error => {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        }
    }
}