import LvColorpicker from 'lightvue/color-picker';

export default {
	components: {
		LvColorpicker
	},
	data() {
		return {
			// stateCode: this.$route.query.stateCode,
			// stateName: this.$route.query.stateName,
			url: this.$serverURL + this.$api.cms.commonBannerCmsURL,
			cmsData: {},
			previewImage: '',
			dataLoaded: false,
			colors: [
				"#E91E63",
				"#F44336",
				"#9C27B0",
				"#673AB7",
				"#3F51B5",
				"#2196F3",
				"#03A9F4",
				"#00BCD4",
				"#009688",
				"#4CAF50",
				"#8BC34A",
				"#CDDC39",
				"#FFEB3B",
				"#FFC107",
				"#FF9800",
				"#795548"
			]
		}
	},
	async created() {
		document.title = "JARS - Common Banner CMS";
		this.getData();
	},
	methods: {
		async getData() {
			let response = await this.getCmsData(this.url);
			if (response.statusCode == 200) {
				this.cmsData = (response.data.length > 0) ? response.data[0] : {};
				this.dataLoaded = true;
			}
			else {
				this.cmsData = {};
				this.dataLoaded = false;
			}
		},
		onImageChange: function (event) {
			this.cmsData.image = event.target.files[0];
			let input = this.$refs.imageFile;
			let file = input.files;
			if (file && file[0]) {
				let reader = new FileReader;
				reader.onload = e => {
					this.previewImage = e.target.result;
				}
				reader.readAsDataURL(file[0]);
			}
		},
		async update() {
			try {
				let formData = new FormData();
				// formData.append('stateCode', this.stateCode);
				// formData.append('stateName', this.stateName);
				formData.append('image', this.cmsData.image);
				formData.append('imageBackgroundColor', this.cmsData.imageBackgroundColor ? this.cmsData.imageBackgroundColor : '#000000');
				formData.append('title', this.cmsData.title);
				formData.append('subTitle', this.cmsData.subTitle);
				formData.append('buttonText', this.cmsData.buttonText);
				formData.append('buttonLink', this.cmsData.buttonLink);
				formData.append('isImageOrColor', this.cmsData.isImageOrColor);
				formData.append('buttonBgColor', this.cmsData.buttonBgColor);
				formData.append('buttonFontColor', this.cmsData.buttonFontColor);
				// formData.append('isDefault', this.cmsData.isDefault);
				// let data = {
				// 	stateCode: this.stateCode,
				// 	stateName: this.stateName,
				// 	title: this.cmsData.title,
				// 	subTitle: this.cmsData.subTitle,
				// 	buttonText: this.cmsData.buttonText,
				// 	buttonLink: this.cmsData.buttonLink,
				// 	isDefault: this.cmsData.isDefault,
				// };
				this.$swal.showLoading();
				let response = await this.createUpdateCMS(this.url, formData);
				if (response.statusCode == 200 || response.statusCode == 201) {
					this.$swal.fire({
						toast: true,
						position: 'top-end',
						text: response.message,
						icon: "success",
						showConfirmButton: false,
						timer: 3000,
						animation: false,
						timerProgressBar: true,
						didOpen: (toast) => {
							toast.addEventListener('mouseenter', this.$swal.stopTimer)
							toast.addEventListener('mouseleave', this.$swal.resumeTimer)
						}
					});
					this.previewImage = "";
					this.$refs.imageFile.value = "";
				}
				await this.getData();
			} catch (error) {
				this.$swal.fire({
					icon: "error",
					text: "Something went wrong. Please try again!"
				});
			} finally {
				this.$swal.hideLoading();
			}
		}
	}
}