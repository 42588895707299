<template>
  <div class="page-wrapper">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Category</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/home">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">State Wise Category</li>
            </ol>
          </nav>
        </div>
        <div class="ms-auto">
          <div class="btn-group"></div>
        </div>
      </div>
      <h6 class="mb-0 text-uppercase">State Wise Category List</h6>
      <hr />
      <div class="card">
        <div class="card-body" style="display: flex; align-items: center;">
          <h5 style="margin: 0;">{{stateName}}</h5>
          <div
            style="display: flex; align-items: center; cursor: pointer; margin-left: auto;"
            @click="$router.push({ path: '/Category-states'})"
          >
            <span style="margin-right: 5px;">
              <i class="bx bxs-arrow-to-left text-dark" style="font-size: 30px;"></i>
            </span>
            <h6 style="margin: 0; cursor:pointer; font-weight: 600;">Go Back</h6>
          </div>
        </div>
      </div>
      <div
        class="page-breadcrumb d-none d-sm-flex align-items-center mb-3"
        v-if="dataList.length>0"
      >
        <div class="ms-auto">
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-primary"
              @click="handleSetDefault"
              :disabled="isDefault"
            >
              <i class="bx bxs-plus-square"></i>Set Default
            </button>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="p-2 mt-2" style="margin-right:5px">
          <div style="float:right">
            <input
              type="text"
              class="form-control"
              placeholder="Search category"
              v-model="searchKey"
            />
          </div>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table id class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Sl</th>
                  <th>Name</th>
                  <th>Created At</th>
                  <th>Hide/Show</th>
                </tr>
              </thead>
              <draggable
                v-model="categoryList"
                tag="tbody"
                item-key="data.id"
                @update="onDragUpdate"
              >
                <template #item="{ element: row }">
                  <tr :class="searchKey ? 'disable-row' : ''">
                    <td>{{ row.position }}</td>
                    <td>{{ row.categoryName }}</td>
                    <td>{{ $filters.formatDate(row.createdAt) }}</td>
                    <td>
                      <div class="form-check form-switch">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          v-model="row.isShown"
                          id="flexSwitchCheckDefault"
                          @change="handleUpdateHideShow(row)"
                        />
                      </div>
                    </td>
                  </tr>
                </template>
              </draggable>
              <tfoot>
                <tr>
                  <th>Sl</th>
                  <th>Name</th>
                  <th>Created At</th>
                  <th>Hide/Show</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<style scoped>
@import "/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";

.edit-button {
  background-color: #17a00e;
}

.delete-button {
  background-color: #f41127;
}
</style>

<script src="../js/state-wise-category.js"></script>