<template>
    <body class="bg-login">
        <div class="wrapper">
            <div class="section-authentication-signin d-flex align-items-center justify-content-center my-5 my-lg-0">
                <div class="container-fluid">
                    <div class="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
                        <div class="col mx-auto">
                            <div class="card">
                                <div class="card-body">
                                    <div class="border p-4 rounded">
                                        <div class="text-center">
                                            <h3 class="">Admin Portal Login</h3>
                                        </div>
                                        <div class="form-body">
                                            <form class="row g-3" @submit.prevent="login">
                                                <div class="col-12">
                                                    <label for="inputEmailAddress" class="form-label">Email Address</label>
                                                    <input type="email" v-model="email" class="form-control" id="inputEmailAddress" placeholder="Email Address" />
                                                </div>
                                                <div class="col-12">
                                                    <label for="inputPassword" class="form-label">Enter Password</label>
                                                    <div class="input-group" id="show_hide_password">
                                                        <input :type="inputType" v-model="password" class="form-control border-end-0" id="inputPassword" placeholder="Enter Password" />
                                                        <a @click="changeInputType()" href="javascript:void(0)" class="input-group-text bg-transparent">
                                                            <i :class="iconFlag ? 'bx bx-hide' : 'bx bx-show'"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="d-grid">
                                                        <button type="submit" class="btn btn-primary">
                                                            <i class="bx bxs-lock-open"></i>Sign In
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </body>
</template>

<script src="../js/login.js"></script>
