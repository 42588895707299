<template>
	<div class="page-wrapper">
		<loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
		<div class="page-content">
			<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
				<div class="breadcrumb-title pe-3">Delivery Pages</div>
				<div class="ps-3">
					<nav aria-label="breadcrumb">
						<ol class="breadcrumb mb-0 p-0">
							<li class="breadcrumb-item">
								<router-link to="/dashboard"><i class="bx bx-home-alt"></i></router-link>
							</li>
							<li class="breadcrumb-item active" aria-current="page">
								Delivery Pages
							</li>
						</ol>
					</nav>
				</div>
				<div class="ms-auto">
					<div class="btn-group">
						<button type="button" class="btn btn-primary" @click="$router.push('/delivery-pages')">
							<i class="bx bx-coin-stack"></i> Delivery Pages List
						</button>
					</div>
				</div>
			</div>
			<div class="card">
				<div class="card-body">
					<div class="row">
						<div class="col-xl-12 mx-auto">
							<h6 class="mt-2 mb-2 text-uppercase">Edit Delivery Page</h6>
							<hr>
							<div class="form-body mt-4">
								<div class="row mb-3 justify-content-center">
									<div class="col-lg-11">
										<div class="border border-3 p-1 rounded">
											<div class="card-body p-5">
												<div class="card-title d-flex align-items-center">
													<div><i class="bx bxs-user me-1 font-22 text-primary"></i></div>
													<h5 class="mb-0 text-primary">Edit Delivery Page Information</h5>
												</div>
												<hr>
												<form class="row g-3">
													<div class="col-md-12">
														<label for="title" class="form-label">Banner Title: <span
																style="color: red; font-size: 18px">*</span></label>
														<input type="text" v-model="title" class="form-control" />
													</div>
													<div class="col-md-12">
														<label for="subTitle" class="form-label">Banner Subtitle: <span
																style="color: red; font-size: 18px">*</span></label>
														<input type="text" v-model="subTitle" class="form-control" />
													</div>
													<div class="col-12 mb-3">
														<label class="form-label">Banner Description: <span
																style="color: red; font-size: 18px">*</span></label>
														<ckeditor :editor="editor" v-model="description"
															:config="editorConfig"></ckeditor>
													</div>
													<div class="col-md-12">
														<label class="form-label">Banner Background Image: [Image Size:
															1920X1280, Max Limit: 1MB] <span
																style="color: red; font-size: 18px">*</span></label>
														<input class="form-control" type="file" ref="image"
															@change="onImageChange" />
													</div>
													<div class="mb-3">
														<div class="col-md-12">
															<img v-if="previewImage" class="img-thumbnail"
																:src="previewImage" width="400" />
															<img v-else-if="bannerBackgroundImage" class="img-thumbnail"
																:src="bannerBackgroundImage" width="400" />
															<img v-else class="img-thumbnail"
																src="../../../assets/images/default.png" height="100"
																width="150" />
														</div>
													</div>

													<div class="col-md-12">
														<label for="buttonText" class="form-label"> Button Text: <span
																style="color: red; font-size: 18px">*</span></label>
														<input type="text" v-model="buttonText" class="form-control" />
													</div>
													<div class="col-md-12">
														<label for="buttonLink" class="form-label">Button Link: <span
																style="color: red; font-size: 18px">*</span></label>
														<input type="text" v-model="buttonLink" class="form-control" />
													</div>

													<div class="col-md-12">
														<label class="form-label">
															Location Address:
															<span style="color: red; font-size: 18px">*</span>
														</label>
														<GMapAutocomplete @place_changed="handleAddressLatLng"
															:value="address" :class="'form-control'"></GMapAutocomplete>
													</div>

													<div class="col-md-12">
														<label class="form-label">
															Jars Map Link:
															<span style="color: red; font-size: 18px">*</span>
														</label>
														<GMapMap :center="center" :zoom="6" map-type-id="terrain"
															style="height: 700px">
															<GMapMarker :key="index" v-for="(m, index) in markers"
																:position="m.position" :clickable="true" :draggable="true"
																@drag="setPlace($event)"></GMapMarker>
														</GMapMap>
													</div>

													<!-- <div class="col-md-12">
														<label for="lattitude" class="form-label">Lattitude: <span
																style="color: red; font-size: 18px">*</span></label>
														<input type="text" v-model="lattitude" class="form-control" />
													</div>
													<div class="col-md-12">
														<label for="longitude" class="form-label">Longitude: <span
																style="color: red; font-size: 18px">*</span></label>
														<input type="text" v-model="longitude" class="form-control" />
													</div> -->
													<div class="col-md-12">
														<label for="bodyTitle" class="form-label">Body Title: <span
																style="color: red; font-size: 18px">*</span></label>
														<input type="text" v-model="bodyTitle" class="form-control" />
													</div>
													<div class="col-12 mb-3">
														<label class="form-label">Body Description: <span
																style="color: red; font-size: 18px">*</span></label>
														<ckeditor :editor="editor" v-model="bodyDescription"
															:config="editorConfig"></ckeditor>
													</div>

													<div class="col-md-12">
														<label for="urlSlug" class="form-label">Page URL Slug (starts with
															<span
																class="text-primary">https://jarscannabis.com/delivery/</span>):</label>
														<input type="text" v-model="urlSlug" class="form-control" />
														<!-- <div v-if="urlSlug" class="mt-2">https://jarscannabis.com/delivery/{{createUrlSlug(urlSlug)}}</div> -->
													</div>

												</form>
											</div>
										</div>
										<div class="border border-3 p-1 rounded mt-3">
											<div class="card-body p-5">
												<div class="row g-3">
													<div class="col-md-12">
														<label for="pageTitle" class="form-label">Page Title: </label>
														<input type="text" v-model="pageTitle" class="form-control" />
													</div>
													<div class="col-md-12">
														<label for="metaDescription" class="form-label">Meta Description:
															<span style="color: red; font-size: 18px">*</span></label>
														<input type="text" v-model="metaDescription" class="form-control" />
													</div>
													<div class="col-md-12">
														<label for="metaKeyword" class="form-label">Meta Keyword: <span
																style="color: red; font-size: 18px">*</span></label>
														<input type="text" v-model="metaKeyword" class="form-control" />
													</div>
												</div>
											</div>
										</div>

										<div class="d-grid mt-5">
											<button v-on:click="updatePost" type="button"
												class="btn btn-primary">Update</button>
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script src="../js/edit-delivery-page.js"></script>

<style scoped>
@import '../../../../public/core/assets/plugins/perfect-scrollbar/css/perfect-scrollbar.css';
@import '../../../../public/core/assets/plugins/metismenu/css/metisMenu.min.css';
@import '../../../../public/core/assets/plugins/smart-wizard/css/smart_wizard_all.min.css';

.margin-auto {
	margin-left: 480px;
}</style>