// import { authHeader } from '../../../auth';

export default {
    name: "AIQ Personas",
    data() {
        return {
            dataList: [],
            singleData: {},
            retailerId: ""
        }
    },
    async created() {
        document.title = "JARS - AIQ Personas";
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    async mounted() {
        if(this.$route.params.id != undefined) {
            this.retailerId = this.$route.params.id;
            let setURL = this.$serverURL + this.$api.aiqPersonas.allPersonasURL + '?retailerId=' +  this.retailerId;
            const aiqData = await this.getDataList(setURL);
            this.dataList = aiqData.results;
        }
    },
    methods: {
        singlePersonas: async function (contactID) {
            let url = this.$serverURL + this.$api.aiqPersonas.detailsPersonasURL + '?retailerId=' + this.retailerId + '&contactId=' + contactID;
            const singlePersonas = await this.getSingleData(url);
            this.singleData = singlePersonas.data;
        }
    }
}