import { authHeader } from '../../../auth';

export default {
    name: "AiqService",
    data() {
        return {
            dataList: [],
            setURL: this.$serverURL + this.$api.retailer.requestURL,
            singleData: {}
        }
    },
    async created() {
        document.title = "JARS - Retailer AIQ Service";
        this.dataList = await this.getDataList(this.setURL);
        console.log("Data: ", this.dataList);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        editData(data) {
            this.singleData = data;
        },
        update: async function () {
            const data = { 
                alpineIqApiKey: this.singleData.alpineIqApiKey,
                alpineIqUID: this.singleData.alpineIqUID
            }
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.retailerAIQService.requestURL + '/' + this.singleData.id,
                data: data,
                headers: authHeader()
            };
            this.$swal.showLoading();
            await this.$axios(config).then(async (response) => {
                this.$swal.close();
                if (response.data.statusCode == 200) {
                    this.singleData = {};
                    this.dataList = await this.getDataList(this.setURL);
                    document.getElementById("close-btn").click();
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
            }).catch(error => {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        }
    }
}