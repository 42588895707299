export default {
    name: "AddBenefits",
    data() {
        return {
            title: null,
            subTitle: null,
            termsAndConditionTitle: null,
            image: '',
            previewImage: '',
        }
    },
    async created() {
        document.title = "JARS - Add Benefits Modal";
        var scripts = [
            "/core/assets/js/jquery.min.js",
            "/core/assets/plugins/simplebar/js/simplebar.min.js",
            "/core/assets/plugins/metismenu/js/metisMenu.min.js",
            "./core/assets/plugins/perfect-scrollbar/js/perfect-scrollbar.js",
            "/core/assets/plugins/smart-wizard/js/jquery.smartWizard.min.js",
            "/core/assets/js/form-wizard.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    methods: {
        onImageChange(event) {
            this.image = event.target.files[0];
            let input = this.$refs.imageFile;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewImage = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        validate: function () {
            if (!this.title) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter title!"
                });
                return false;
            }
            if (!this.subTitle) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter sub title!"
                });
                return false;
            }
            if (!this.image) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please upload article image!"
                });
                return false;
            }
            return true;
        },
        save: async function () {
            if (this.validate() == true) {
                let token = localStorage.getItem('token');
                const formData = new FormData();
                formData.append("image", this.image);
                formData.append("title", this.title);
                formData.append("subTitle", this.subTitle);
                formData.append("termsAndConditionTitle", this.termsAndConditionTitle);
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.cms.benefitsModal,
                    data: formData,
                    headers: {
                        "Authorization": "Bearer " + token,
                        "Content-Type": "multipart/form-data"
                    }
                };
                await this.$axios(config).then((response) => {
                    if (response.status == 201) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                    }
                    this.title = "";
                    this.subTitle = "";
                    this.termsAndConditionTitle = "";
                    this.$refs.imageFile.value = null;
                    this.image = "";
                    this.$router.push("/benefits-modal");
                }).catch(error => {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        }
    }
}