import { authHeader } from '../../../auth';
import Multiselect from '@vueform/multiselect';

export default {
    name: "Weekly Ad",
    components: { Multiselect },
    data() {
        return {
            stateCode: this.$route.query.stateCode,
            stateName: this.$route.query.stateName,
            setURL: this.$serverURL + this.$api.weeklyAd.requestURL,
            tagURL: this.$serverURL + this.$api.weeklyAd.tagURL,
            backgroundImage: '',
            previewImage: '',
            previewUpdateImage: '',
            updateImage: '',

            title: '',
            buttonText: '',
            buttonLink: '',

            dataList: [],
            singleData: {},
            selectedWeeklyTags: [],
            weeklyAdTags: []
        }
    },
    async created() {
        document.title = "JARS - Weekly Ad";
        this.dataList = await this.getDataList(this.setURL + '?stateCode=' + this.stateCode);
        const tagsList = await this.getDataList(this.tagURL);
        // this.weeklyAdTags = tagsList?.map((tag) => { return { label: tag?.tagName, value: { tagName: tag?.tagName, tagId: tag?.id } } });
        this.weeklyAdTags = tagsList?.map((tag) => { return { label: tag?.tagName, value: tag?.id } });

        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        onImageChange: function (event) {
            this.backgroundImage = event.target.files[0];
            let input = this.$refs.image;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewImage = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        onUpdateImageChange: function (event) {
            this.updateImage = event.target.files[0];
            let input = this.$refs.updateImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewUpdateImage = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        validate: function () {
            if (!this.backgroundImage) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please upload background image!"
                });
                return false;
            }
            if (!this.title) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter title!"
                });
                return false;
            }
            if (!this.buttonText) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter button text!"
                });
                return false;
            }
            if (!this.buttonLink) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter button link!"
                });
                return false;
            }
            if (this.selectedWeeklyTags?.length == 0) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please select at least one tag!"
                });
                return false;
            }
            return true;
        },
        addEntity: async function () {
            if (this.validate() == true) {
                let token = localStorage.getItem('token');
                const formData = new FormData();
                formData.append("stateCode", this.stateCode);
                formData.append("stateName", this.stateName);
                formData.append("backgroundImage", this.backgroundImage);
                formData.append("title", this.title);
                formData.append("buttonText", this.buttonText);
                formData.append("buttonLink", this.buttonLink);
                const tags = this.weeklyAdTags?.map((tag) => {
                    if (this.selectedWeeklyTags?.includes(tag?.value)) return { tagName: tag?.label, tagId: tag?.value }
                }).filter(item => (item !== null && item !== undefined));
                formData.append("tags", JSON.stringify(tags));
                // formData.append("tags", JSON.stringify(this.selectedWeeklyTags));

                let config = {
                    method: "POST",
                    url: this.setURL,
                    data: formData,
                    headers: {
                        "Authorization": "Bearer " + token,
                        "Content-Type": "multipart/form-data"
                    }
                };
                await this.$axios(config).then(async (response) => {
                    if (response.status == 201) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                    }
                    this.backgroundImage = '';
                    this.previewImage = '';
                    this.$refs.image.value = null;
                    this.title = '';
                    this.buttonText = '';
                    this.buttonLink = '';
                    this.selectedWeeklyTags = [];
                    this.dataList = await this.getDataList(this.setURL + '?stateCode=' + this.stateCode);
                    document.getElementById("closeButton").click();
                }).catch(error => {
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        },
        deleteEntity: function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.setURL + '/' + id,
                            headers: authHeader()
                        }
                        await this.deleteData(config);
                        this.dataList = await this.getDataList(this.setURL + '?stateCode=' + this.stateCode);
                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    }
                }
            })
        },
        editEntity: async function (data) {
            this.singleData = data;
            this.selectedWeeklyTags = data?.tags?.map((tag) => tag?.tagId);
        },
        updateValidation: function () {
            if (!this.singleData.backgroundImage) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter background image!"
                });
                return false;
            }
            if (!this.singleData.title) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter title!"
                });
                return false;
            }
            if (!this.singleData.buttonText) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter button text!"
                });
                return false;
            }
            if (!this.singleData.buttonLink) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter button link!"
                });
                return false;
            }
            if (this.selectedWeeklyTags?.length == 0) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please select at least one tag!"
                });
                return false;
            }
            return true;
        },
        updateEntity: async function () {
            if (this.updateValidation() == true) {
                let token = localStorage.getItem('token');
                const formData = new FormData();
                formData.append("stateCode", this.stateCode);
                formData.append("stateName", this.stateName);
                formData.append("backgroundImage", this.updateImage ? this.updateImage : this.singleData.backgroundImage);
                formData.append("title", this.singleData.title);
                formData.append("buttonText", this.singleData.buttonText);
                formData.append("buttonLink", this.singleData.buttonLink);
                // formData.append("tags", JSON.stringify(this.selectedWeeklyTags));
                const tags = this.weeklyAdTags?.map((tag) => {
                    if (this.selectedWeeklyTags?.includes(tag?.value)) return { tagName: tag?.label, tagId: tag?.value, weeklyAdId: this.singleData?.id }
                }).filter(item => (item !== null && item !== undefined));
                formData.append("tags", JSON.stringify(tags));

                let config = {
                    method: "PATCH",
                    url: this.setURL + '/' + this.singleData.id,
                    data: formData,
                    headers: {
                        "Authorization": "Bearer " + token,
                        "Content-Type": "multipart/form-data"
                    }
                };
                await this.$axios(config).then(async (response) => {
                    if (response.status == 200) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                    }
                    this.singleData = {};
                    this.updateImage = '';
                    this.previewUpdateImage = '';
                    this.$refs.updateImage.value = null;
                    this.selectedWeeklyTags = [];
                    this.dataList = await this.getDataList(this.setURL + '?stateCode=' + this.stateCode);
                    document.getElementById("buttonClose").click();
                }).catch(error => {
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        }
    }
}