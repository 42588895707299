import { authHeader } from "../../../auth";
import Chart from 'chart.js/auto';
import LinkShortcut from "../../layouts/template/LinkShortcut";

export default {
    components: {
        LinkShortcut
    },
    data() {
        return {
            setURL: this.$serverURL + this.$api.dashboard.dashboardAPI,
            totalRetailer: 0,
            totalCareer: 0,
            totalCareerApplicant: 0,
            totalPressRelease: 0,
            totalArticle: 0,
            totalFeedback: 0,
            totalContact: 0,
            topRetailer: {}
        }
    },
    async created() {
        document.title = "JARS - Dashboard";
        await this.getDashBoardData();
        await this.loadJS();
        await this.getPieChart();
    },
    methods: {
        async loadJS() {
            var scripts = [
                "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
                "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
                "/core/assets/js/table-datatable.js",
                "/core/assets/plugins/metismenu/js/metisMenu.min.js"
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);
            });
        },
        async getPieChart() {
                new Chart(document.getElementById("retailerChart"), {
                    type: 'doughnut',
                    data: {
                        labels: this.topRetailer.labels,
                        datasets: [{
                            label: 'Top Retailer',
                            data: this.topRetailer.data,
                            backgroundColor: [
                                'rgb(255, 99, 132)',
                                'rgb(54, 162, 235)',
                                'rgb(255, 205, 86)',
                                'rgb(50, 168, 68)',
                                'rgb(50, 131, 168)'
                            ],
                            hoverOffset: 4
                        }]
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        parsing: {
                            key: 'nested.value'
                        },
                        plugins: {
                            title: {
                                display: true,
                                text: 'Top Five Retailers',
                                padding: {
                                    top: 10,
                                    bottom: 30
                                }
                            }
                        }
                    }
                });
        },
        async getDashBoardData() {
            let config = {
                method: "GET",
                url: this.setURL,
                headers: authHeader()
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.totalRetailer = response.data.data.totalRetailer;
                    this.totalCareer = response.data.data.totalCareer;
                    this.totalCareerApplicant = response.data.data.totalCareerApplicant;
                    this.totalPressRelease = response.data.data.totalPressRelease;
                    this.totalArticle = response.data.data.totalArticle;
                    this.totalFeedback = response.data.data.totalFeedback;
                    this.totalContact = response.data.data.totalContact;
                    this.topRetailer = response.data.data.topFiveRetailer;
                }
            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!" + ' ' + error
                });
            });
        }
    }
}