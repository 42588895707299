<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Career Page</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/home">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Career Page CMS</li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Career Page CMS</h5>
          <hr />
          <div class="form-body mt-4">
            <div class="row">
              <div class="col-lg-12">
                <div class="border border-3 p-4 rounded">
                  <div class="row mb-3">
                    <div class="col-sm-6">
                      <label class="form-label">Hero Section Title One:</label>
                      <input type="text" v-model="cmsData.heroSectionTitleOne" class="form-control" />
                    </div>
                    <div class="col-sm-6">
                      <label class="form-label">Hero Section Title Two:</label>
                      <input type="text" v-model="cmsData.heroSectionTitleTwo" class="form-control" />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-sm-6">
                      <label class="form-label">Hero Section Button Text:</label>
                      <input type="text" v-model="cmsData.heroSectionBtnText" class="form-control" />
                    </div>
                    <!-- <div class="col-sm-6">
                      <label class="form-label">Hero Section Button Link:</label>
                      <input type="text" v-model="cmsData.heroSectionBtnLink" class="form-control" />
                    </div> -->
                  </div>
                  <div class="row mb-3">
                    <div class="col-sm-6">
                      <label class="form-label">Hero Section Description:</label>
                      <textarea class="form-control" v-model="cmsData.heroSectionDescription" />
                    </div>
                    <div class="col-sm-6">
                      <label class="form-label">Hero Background Image:</label>
                      <div class="row">
                        <div class="col-sm-6">
                          <label
                            class="form-label"
                          >Image: [Preferred Iocn Size: 1485X700, Max Upload Limit: 1MB]</label>
                          <input
                            class="form-control"
                            @change="onImageChange"
                            type="file"
                            ref="bgImage"
                          />
                        </div>
                        <div class="col-sm-6">
                          <div class="col-md-3">
                            <img v-if="previewImage" :src="previewImage" style="height:100px;" />
                            <img v-else-if="cmsData.heroSectionBgImage" :src="cmsData.heroSectionBgImage" style="height:100px;" />
                            <img v-else src="../../../../assets/images/default.png" height="100" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />

                  <div class="row mt-3 mb-3">
                    <div class="col-sm-6">
                      <label class="form-label">Section Two Title:</label>
                      <input type="text" v-model="cmsData.sectionTwoTitle" class="form-control" />
                    </div>
                    <div class="col-sm-6">
                      <label class="form-label">Section Two Sub Title:</label>
                      <input type="text" v-model="cmsData.sectionTwoSubTitle" class="form-control" />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-sm-6">
                      <label class="form-label">Section Two Content One:</label>
                      <ckeditor
                        :editor="editor"
                        v-model="cmsData.sectionTwoContentOne"
                        :config="editorConfig"
                      ></ckeditor>
                    </div>
                    <div class="col-sm-6">
                      <label class="form-label">Section Two Content Two:</label>
                      <ckeditor
                        :editor="editor"
                        v-model="cmsData.sectionTwoContentTwo"
                        :config="editorConfig"
                      ></ckeditor>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-sm-12">
                      <label class="form-label">section Two End Content:</label>
                      <textarea class="form-control" v-model="cmsData.sectionTwoEndContent" />
                    </div>
                  </div>

                  <!-- <div class="mb-3">
                    <label class="form-label">Modal Title:</label>
                    <input type="text" v-model="cmsData.modalTitle" class="form-control" />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Modal Sub Title:</label>
                    <input type="text" v-model="cmsData.modalSubTitle" class="form-control" />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Modal Description:</label>
                    <textarea class="form-control" v-model="cmsData.modalDescription" />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Modal Button Text:</label>
                    <input type="text" v-model="cmsData.modalButtonText" class="form-control" />
                  </div>-->
                  <div class="d-grid">
                    <button v-on:click="update()" type="button" class="btn btn-primary">Save</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/career-page-cms.js"></script>