import { authHeader } from '../../../../../auth';
export default {
  data() {
    return {
      // stateCode: this.$route.query.stateCode,
      // stateName: this.$route.query.stateName,
      url: this.$serverURL + this.$api.cms.home.sectionFourCmsURL,
      cmsData: {},

      singleSlider: {},
      title: "",
      type: "",
      buttonText: "",
      buttonLink: "",
      buttonTwoText: "",
      buttonTwoLink: "",
      setURL: this.$serverURL + this.$api.cms.home.sectionFourSliderURL,
      dataList: [],
    }
  },
  async created() {
    document.title = "JARS - Section Four CMS";
    await this.getData();
    this.dataList = await this.getDataList(this.setURL);
    await this.loadCss();
  },
  methods: {
    async getData() {
      let response = await this.getCmsData(this.url);
      if (response.statusCode == 200) {
        this.cmsData = (response.data.length > 0) ? response.data[0] : {};
      }
      else {
        this.cmsData = {};
      }
    },
    async update() {
      try {
        let data = {
          // stateCode: this.stateCode,
          // stateName: this.stateName,
          title: this.cmsData.title,
          subTitle: this.cmsData.subTitle,
          sliderButtonText: this.cmsData.sliderButtonText,
          buttonText: this.cmsData.buttonText,
          buttonLink: this.cmsData.buttonLink,
          // isDefault: this.cmsData.isDefault,
        };
        this.$swal.showLoading();
        let response = await this.createUpdateCMS(this.url, data);
        if (response.statusCode == 200 || response.statusCode == 201) {
          this.$swal.fire({
            toast: true,
            position: 'top-end',
            text: response.message,
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
            animation: false,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', this.$swal.stopTimer)
              toast.addEventListener('mouseleave', this.$swal.resumeTimer)
            }
          });
        }
        await this.getData();
      } catch (error) {
        this.$swal.fire({
          icon: "error",
          text: "Something went wrong. Please try again!"
        });
      } finally {
        this.$swal.hideLoading();
      }
    },
    loadCss() {
      var scripts = [
        "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
        "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
        "/core/assets/js/table-datatable.js"
      ];
      scripts.forEach(script => {
        let tag = document.createElement("script");
        tag.setAttribute("src", script);
        document.head.appendChild(tag);
      });
      let style = document.createElement('link');
      style.type = "text/css";
      style.rel = "stylesheet";
      style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
      document.head.appendChild(style);
    },
    addSlider: async function () {
      if (!this.title) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter title!"
        });
      }
      else if (!this.type) {
        this.$swal.fire({
          icon: "error",
          text: "Please select a type!"
        });
      }
      else if (!this.buttonText) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter button text!"
        });
      }
      else if (!this.buttonTwoText) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter button two text!"
        });
      }
      else {
        let data = {
          // stateCode: this.stateCode,
          // stateName: this.stateName,
          title: this.title,
          type: this.type,
          buttonText: this.buttonText,
          buttonLink: this.buttonLink,
          buttonTwoText: this.buttonTwoText,
          buttonTwoLink: this.buttonTwoLink
        };
        let config = {
          method: "POST",
          url: this.setURL,
          data: data,
          headers: authHeader()
        };
        let response = await this.postData(config);
        if (response.statusCode == 201) {
          this.$swal.fire({
            toast: true,
            position: 'top-end',
            text: response.message,
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
            animation: false,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', this.$swal.stopTimer)
              toast.addEventListener('mouseleave', this.$swal.resumeTimer)
            }
          });
        }
        this.title = '';
        this.type = '';
        this.buttonText = '';
        this.buttonLink = '';
        this.buttonTwoText = '';
        this.buttonTwoLink = '';
        this.dataList = await this.getDataList(this.setURL);
        document.getElementById("closeButton").click();
      }
    },
    editSlider: async function (data) {
      this.singleSlider = data;
    },
    updateSlider: async function () {
      if (!this.singleSlider.title) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter title!"
        });
      }
      else if (!this.singleSlider.type) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter type!"
        });
      }
      else if (!this.singleSlider.buttonText) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter button text!"
        });
      }
      else if (!this.singleSlider.buttonTwoText) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter button two text!"
        });
      }
      else {
        let data = {
          // stateCode: this.stateCode,
          // stateName: this.stateName,
          title: this.singleSlider.title,
          type: this.singleSlider.type,
          buttonText: this.singleSlider.buttonText,
          buttonLink: this.singleSlider.buttonLink,
          buttonTwoText: this.singleSlider.buttonTwoText,
          buttonTwoLink: this.singleSlider.buttonTwoLink,
        };
        let config = {
          method: "PATCH",
          url: this.setURL + '/' + this.singleSlider.id,
          data: data,
          headers: authHeader()
        };
        let response = await this.updateData(config);
        if (response.statusCode == 200) {
          this.$swal.fire({
            toast: true,
            position: 'top-end',
            text: response.message,
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
            animation: false,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', this.$swal.stopTimer)
              toast.addEventListener('mouseleave', this.$swal.resumeTimer)
            }
          });
        }
        this.singleQuiz = {};
        this.dataList = await this.getDataList(this.setURL);
        document.getElementById("buttonClose").click();
      }
    },
    deleteSlider: function (id) {
      this.$swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete'
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            let config = {
              method: 'DELETE',
              url: this.setURL + '/' + id,
              headers: authHeader()
            }
            await this.deleteData(config);
            this.dataList = await this.getDataList(this.setURL);
          } catch (error) {
            this.$swal.fire({
              icon: "error",
              text: error.response.data.message
            });
          }
        }
      })

    },
  }
}