<template>
    <div>
        <div v-if="isLoginPage == false" class="wrapper">
            <Header v-if="watchLoginCheck == true"></Header>
            <Sidebar v-if="watchLoginCheck == true"></Sidebar>
            <router-view></router-view>
            <Footer></Footer>
        </div>
        <div v-else class="wrapper">
            <router-view v-if="isLoginPage == true"></router-view>
        </div>
    </div>
</template>

<script>
import Header from "./components/layouts/template/Header";
import Sidebar from "./components/layouts/template/Sidebar";
import Footer from "./components/layouts/template/Footer";
import { authHeader } from "./auth";

export default {
    name: "App",
    components: {
        Header,
        Sidebar,
        Footer
    },
    data() {
        return {
            token: localStorage.getItem("token"),
            fullPage: true,
            loader: "bars",
            isLoginPage: false,
            watchLoginCheck: false
        };
    },
    created() {
        if (this.$router.currentRoute.path == "/admin-login") {
            if (this.token) {
                this.isLoginPage = false;
                this.$router.push("/home");
            } else if (!this.token) {
                this.isLoginPage = true;
                this.watchLoginCheck = false;
            }
            this.isLoginPage = true;
        } else {
            this.isLoginPage = false;
        }
        this.onInactive(1800000, function () {
            this.$swal.fire({
                icon: "error",
                text: "You have been logged out for 30 minutes of inactivity!"
            });
            localStorage.clear();
            this.$router.push("/admin-login");
        });
    },
    methods: {
        onInactive: function (ms, cb) {
            var wait = setTimeout(cb, ms);
            document.onmousemove = document.mousedown = document.mouseup = document.onkeydown = document.onkeyup = document.focus = function () {
                clearTimeout(wait);
                wait = setTimeout(cb, ms);
            };
        }
    },
    watch: {
        $route() {
            if (this.$route.path == "/admin-login") {
                if (this.token) {
                    this.isLoginPage = false;
                    this.$router.push("/home");
                } else if (!this.token) {
                    this.isLoginPage = true;
                }
                this.isLoginPage = true;
            } else {
                this.isLoginPage = false;
                if (this.watchLoginCheck == false) {
                    let config = {
                        method: "POST",
                        url: this.$serverURL + this.$api.auth.validateTokenURL,
                        headers: authHeader()
                    };
                    this.$axios(config)
                        .then(response => {
                            if (response.status == 201) {
                                if (response.data === false) {
                                    localStorage.clear();
                                    window.location.href = "/login";
                                } else {
                                    this.watchLoginCheck = true;
                                    this.isLoginPage = false;
                                }
                            }
                            this.isLoading = false;
                        })
                        .catch(error => {
                            console.log(error);
                            this.isLoading = false;
                        });
                }
            }
        }
    }
};
</script>