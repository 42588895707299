export default {
    data() {
        return {
            stateCode: this.$route.query.stateCode,
            stateName: this.$route.query.stateName,
            url: this.$serverURL + this.$api.cms.pickupDeliveryCmsURL,
            cmsData: {}
        }
    },
    async created() {
        document.title = "Jars - Pickup Delivery CMS";
        await this.getData();
        this.dataList = await this.getDataList(this.url + '?stateCode=' + this.stateCode);
    },
    methods: {
        async getData() {
            let response = await this.getCmsData(this.url + '?stateCode=' + this.stateCode);
            if (response.statusCode == 200) {
                this.cmsData = (response.data.length > 0) ? response.data[0] : {};
            }
            else {
                this.cmsData = {};
            }
        },
        handleUploadImage(imageKey, previewImageKey, ref, event) {
            this.cmsData[imageKey] = event.target.files[0];
            let input = this.$refs[ref];
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.preview[previewImageKey] = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        async updateCms() {
            try {
                let fromData = {
                    stateCode: this.stateCode,
                    stateName: this.stateName,
                    pickupSearchTitle: this.cmsData.pickupSearchTitle,
                    pickupInputPlaceholder: this.cmsData.pickupInputPlaceholder,
                    deliveryTitle: this.cmsData.deliveryTitle,
                    deliveryDescription: this.cmsData.deliveryDescription,
                    deliverySearchTitle: this.cmsData.deliverySearchTitle,
                    deliveryInputPlaceholder: this.cmsData.deliveryInputPlaceholder
                }
                this.$swal.showLoading();
                let response = await this.createUpdateCMS(this.url, fromData);
                if (response.statusCode == 200 || response.statusCode == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                await this.getData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        }
    }
}