<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Site Map CMS</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item"><router-link to="/dashboard"><i class="bx bx-home-alt"></i></router-link></li>
                            <li class="breadcrumb-item active" aria-current="page">Site Map CMS</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Site Map CMS</h5>
                    <hr/>
                    <div class="form-body mt-4">
                        <div class="row">
                            <div class="col-lg-10">
                                <div class="border border-3 p-4 rounded">
                                    <div class="mb-3">
                                        <label class="form-label">
                                            <h5>Static Sitemap Data</h5>
                                        </label>
                                        <textarea type="text" class="form-control" cols="30" rows="15" v-model="staticSitemap" placeholder="Enter Static Sitemap Data"></textarea>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">
                                            <h5>Dynamic Sitemap Data</h5>
                                        </label>
                                        <textarea type="text" class="form-control" cols="30" rows="15" v-model="dynamicSitemap" placeholder="Enter Static Sitemap Data"></textarea>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">
                                            <h5>Dynamic Blog Sitemap Data</h5>
                                        </label>
                                        <textarea type="text" class="form-control" cols="30" rows="15" v-model="dynamicBlogSitemap" placeholder="Enter Static Sitemap Data"></textarea>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">
                                            <h5>Dynamic Product Sitemap Data</h5>
                                        </label>
                                        <textarea type="text" class="form-control" cols="30" rows="15" v-model="dynamicProductSitemap" placeholder="Enter Static Sitemap Data"></textarea>
                                    </div>
                                    
                                    <div class="d-flex justify-content-end">
                                        <button @click="generateSitemap" type="button" class="btn btn-primary">Generate Sitemap</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/site-map-cms.js"></script>