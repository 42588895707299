<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Press Release Page CMS</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item">
                                <router-link to="/dashboard">
                                    <i class="bx bx-home-alt"></i>
                                </router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Press Release Page CMS</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <form @submit.prevent="updateCms()">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Press Release Page CMS</h5>
                        <hr/>
                        <div class="form-body mt-4">
                            <div class="row">
                                <div class="col-lg-8 mb-3">
                                    <div class="border border-3 p-4 rounded">
                                        <div class="row">
                                            <div class="col-12 mb-3">
                                                <label class="form-label">Title:</label>
                                                <input type="text" v-model="cmsData.title" class="form-control" placeholder="Enter Title" />
                                            </div>
                                            <div class="col-12 mb-3">
                                                <label class="form-label">Subtitle:</label>
                                                <input type="text" v-model="cmsData.subTitle" class="form-control" placeholder="Enter Subtitle" />
                                            </div>
                                            <div class="col-6 mb-3">
                                                <label class="form-label">Button Text:</label>
                                                <input type="text" v-model="cmsData.buttonText" class="form-control" placeholder="Enter Button Text" />
                                            </div>
                                            <div class="col-6 mb-3">
                                                <label class="form-label">Button Link:</label>
                                                <input type="text" v-model="cmsData.buttonLink" class="form-control" placeholder="Enter Button Link" />
                                            </div>
                                            <div class="col-12">
                                                <div class="mb-3">
                                                    <label class="form-label">Image:
                                                        <strong>[Preferred Image Size: 1920X1280, Max Upload Limit: 1MB]</strong>
                                                    </label>
                                                    <input class="form-control mt-3" type="file" ref="bannerImage" accept="image/*" @change="handleUploadImage('bannerImage', 'bannerImage', 'bannerImage', $event)" />
                                                </div>
                                                <div class="mb-3">
                                                    <div class="col-md-3">
                                                        <img v-if="preview.bannerImage" :src="preview.bannerImage" height="100" />
                                                        <img v-else-if="cmsData.bannerImage" :src="cmsData.bannerImage" height="100" />
                                                        <img v-else src="../../../../assets/images/default.png" height="100" width="150"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-grid col-8">
                                    <button type="submit" class="btn btn-primary">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script src="../js/press-page-cms.js"></script>