// import { authHeader } from '../../../../auth';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { states } from '../../../../../config/states-list';
export default {
    name: "AddCareer",
    data() {
        return {
            jobType: '',
            title: '',
            location: '',
            city: '',
            state: '',
            shortDescription:'',
            description: '',
            salary: '',
            selectedStore: {},
            editor: ClassicEditor,
            editorConfig: {
                ckfinder: {
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL
                }
            },
            careerDepartment: "",
            storeList: [],
            stateList: [],
            allStates: states,
            jobTypeList:['Full TIme', 'Part Time', 'Contractual'],
        }
    },
    async created() {
        document.title = "JARS - Add Career";
        await this.getStoreDataList();

    },
    methods: {
        // getCareerDepartment: async function () {
        //     let config = {
        //         method: "GET",
        //         url: this.$serverURL + this.$api.careerDepartment.requestURL,
        //         headers: authHeader()
        //     };
        //     await this.$axios(config).then((response) => {
        //         if (response.status == 200) {
        //             this.careerDepartment = response.data.data;
        //         }
        //     }).catch(error => {
        //         console.log(error);
        //         this.$swal.fire({
        //             icon: "error",
        //             text: "Something went wrong. Please try again!"
        //         });
        //     });
        // },
        getStoreDataList: async function () {
            try {
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.store.requestURL
                }
                await this.$axios(config).then((response) => {
                    if (response.status == 200) {
                        this.storeList = response.data;
                        let tempStateList = []
                        tempStateList = response.data.map((el) => {
                            const tempstate = this.allStates.filter(state => (state.code == el.addressObject.state));
                            return tempstate[0];
                        });
                        this.stateList = [...new Set(tempStateList)];

                    }
                }).catch(error => {
                    console.log(error);
                });
            } catch (error) {
                console.log(error);
            }
        },
        handleAddress(data) {
            this.location = data.address;
            this.city = data.addressObject.city;
            const tempState = this.stateList.filter(el => (el.code == data.addressObject.state));
            this.state = tempState[0];
        },
        validate: function () {
            if (!this.jobType) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter job type!"
                });
                return false;
            }
            if (!this.title) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter title!"
                });
                return false;
            }
            if (!this.location) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter location!"
                });
                return false;
            }
            if (!this.city) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter city!"
                });
                return false;
            }
            if (!this.state) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter state!"
                });
                return false;
            }
            if (!this.description) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter description!"
                });
                return false;
            }
            if (!this.salary) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter salary!"
                });
                return false;
            }
            return true;
        },
        save: async function () {
            if (this.validate() == true) {
                let token = localStorage.getItem('token');
                let formData = {
                    storeId: this.selectedStore.id,
                    storeName: this.selectedStore.name,
                    city: this.city,
                    state: this.state.name,
                    jobType: this.jobType,
                    title: this.title,
                    location: this.location,
                    shortDescription: this.shortDescription,
                    description: this.description,
                    salary: this.salary,
                }
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.cms.careerURL,
                    data: formData,
                    headers: {
                        "Authorization": "Bearer " + token
                    }
                };
                await this.$axios(config).then((response) => {
                    if (response.status == 201) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                    }
                    this.jobType = '';
                    this.title = '';
                    this.location = '';
                    this.description = '';
                    this.salary = '';
                    this.selectedStore = {};
                    this.$router.push("/career");
                }).catch(error => {
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        }
    }
}