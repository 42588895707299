<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Contact Page CMS</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/dashboard">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Contact Page CMS</li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="card">
        <div class="card-body" style="display: flex; align-items: center;">
          <h5 style="margin: 0;">{{storeName}}</h5>
          <div
            style="display: flex; align-items: center; cursor: pointer; margin-left: auto;"
            @click="$router.push({ path: '/contact-page-store-list'})"
          >
            <span style="margin-right: 5px;">
              <i class="bx bxs-arrow-to-left text-dark" style="font-size: 30px;"></i>
            </span>
            <h6 style="margin: 0; cursor:pointer; font-weight: 600;">Go Back</h6>
          </div>
        </div>
      </div>
      <form @submit.prevent="updateCms()">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Contact Page CMS</h5>
            <hr />
            <div class="form-body mt-4">
              <div class="row">
                <div class="col-lg-12 mb-3">
                  <div class="border border-3 p-4 rounded">
                    <div class="mb-3">
                      <label class="form-label">Title:</label>
                      <input
                        type="text"
                        v-model="cmsData.title"
                        class="form-control"
                        id="inputPageHeaderTitle"
                        placeholder="Enter title"
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">Sub Title:</label>
                      <input
                        type="text"
                        v-model="cmsData.subTitle"
                        class="form-control"
                        id="inputPageHeaderPageName"
                        placeholder="Enter sub title"
                      />
                    </div>

                    <div class="mb-3">
                      <label class="form-label">Email:</label>
                      <input
                        type="email"
                        v-model="cmsData.email"
                        class="form-control"
                        id="inputPageHeaderPageName"
                        placeholder="Enter email"
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">Contact No:</label>
                      <input
                        type="text"
                        v-model="cmsData.phone"
                        class="form-control"
                        id="inputPageHeaderPageName"
                        placeholder="Enter contact no"
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">Button Text:</label>
                      <input
                        type="text"
                        v-model="cmsData.buttonText"
                        class="form-control"
                        id="inputPageHeaderPageName"
                        placeholder="Enter button text"
                      />
                    </div>
                    <div class="form-check form-switch">
                      <label class="form-label">
                        [Override the Jane store details data with this data for contact page.]
                      </label>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="cmsData.isDefault"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="border border-3 p-4 rounded">
                    <div class="mb-3">
                      <label class="form-label">Location Address:</label>
                      <GMapAutocomplete
                        @place_changed="handleAddressLatLng"
                        :value="cmsData.location"
                        :class="'form-control'"
                      ></GMapAutocomplete>
                    </div>
                    <div class="mb-3">
                      <GMapMap
                        :center="center"
                        :zoom="5"
                        map-type-id="terrain"
                        style="width: 876px; height: 736px"
                      >
                        <GMapMarker
                          :key="index"
                          v-for="(m, index) in markers"
                          :position="m.position"
                          :clickable="false"
                          :draggable="false"
                          @click="center = m.position"
                        ></GMapMarker>
                      </GMapMap>
                    </div>
                  </div>
                </div>
                <div class="d-grid">
                  <button type="submit" class="btn btn-primary">Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script src="../js/contact-page.js"></script>