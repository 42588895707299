import { authHeader } from '../../../../../auth';
import LvColorpicker from 'lightvue/color-picker';
import draggable from 'vuedraggable';
export default {
    name: "HomeSlider",
    components: {
        LvColorpicker,
        draggable,
    },
    data() {
        return {
            // stateCode: this.$route.query.stateCode,
            // stateName: this.$route.query.stateName,
            dataList: [],
            tempDataList: [],
            setURL: this.$serverURL + this.$api.cms.home.homeSliderURL,
            titleOne: '',
            titleTwo: '',
            titleOneColor: '',
            titleTwoColor: '',
            description: '',
            descriptionFontColor: '',
            buttonText: '',
            buttonLink: '',
            openNowButtonText: '',
            openNowButtonFile: '',
            showOpenNowButton: false,
            imageFile: '',
            mobileImage: "",
            buttonBackgroundColor: "",
            buttonTextColor: "",
            startDate: undefined,
            endDate: undefined,

            singleContent: {
                titleOne: '',
                titleTwo: '',
                titleOneColor: '',
                titleTwoColor: '',
                description: '',
                descriptionFontColor: '',
                buttonText: '',
                buttonLink: '',
                imageFile: '',
                buttonBackgroundColor: "",
                buttonTextColor: "",
                startDate: undefined,
                endDate: undefined,
            },
            previewImage: null,
            previewImageMobile: null,
            previewUpdateImage: null,
            previewImageMobileUpdate: null,
            dataLoaded: false,
            colors: [
                "#E91E63",
                "#F44336",
                "#9C27B0",
                "#673AB7",
                "#3F51B5",
                "#2196F3",
                "#03A9F4",
                "#00BCD4",
                "#009688",
                "#4CAF50",
                "#8BC34A",
                "#CDDC39",
                "#FFEB3B",
                "#FFC107",
                "#FF9800",
                "#795548"
            ]
        }
    },
    async created() {
        document.title = "JARS - Home Slider";
        this.dataList = await this.getDataList(this.setURL);
        this.tempDataList = this.dataList;
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },

    computed: {
        isDefault() {
            let newArr = this.dataList.filter((el) => (el.isDefault == true));
            return (newArr.length == this.dataList.length) ? true : false;
        },
    },


    methods: {
        onImageChange: function (event) {
            this.imageFile = event.target.files[0];
            let input = this.$refs.imageFile;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewImage = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        onMobileImageChange: function (event) {
            this.mobileImage = event.target.files[0];
            let input = this.$refs.mobileImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewImageMobile = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        handleUploadFile(event) {
            this.openNowButtonFile = event.target.files[0];
        },
        handleStatus(e) {
            this.showOpenNowButton = e.target.checked;
        },
        validate: function () {
            if (!this.titleOne) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter title one!"
                });
                return false;
            }
            if (!this.titleTwo) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter title two!"
                });
                return false;
            }
            if (!this.description) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter description!"
                });
                return false;
            }
            if (!this.buttonText) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter button text!"
                });
                return false;
            }
            if (!this.buttonLink) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter button link!"
                });
                return false;
            }
            if (!this.imageFile) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please upload image!"
                });
                return false;
            }
            if (!this.startDate) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please add start date!"
                });
                return false;
            }
            if (!this.endDate) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please add end date!"
                });
                return false;
            }
            return true;
        },
        save: async function () {
            if (this.validate() == true) {
                let token = localStorage.getItem('token');
                const formData = new FormData();
                // formData.append("stateCode", this.stateCode);
                // formData.append("stateName", this.stateName);
                formData.append("titleOne", this.titleOne);
                formData.append("titleTwo", this.titleTwo);
                formData.append("titleOneColor", this.titleOneColor);
                formData.append("titleTwoColor", this.titleTwoColor);
                formData.append("description", this.description);
                formData.append("descriptionFontColor", this.descriptionFontColor ? this.descriptionFontColor : '#FFFFFF');
                formData.append("buttonText", this.buttonText);
                formData.append("buttonLink", this.buttonLink);
                formData.append("openNowButtonText", this.openNowButtonText);
                formData.append("openNowButtonFile", this.openNowButtonFile);
                formData.append("showOpenNowButton", this.showOpenNowButton ? 1 : 0);
                formData.append("image", this.imageFile);
                formData.append("mobileImage", this.mobileImage);
                formData.append("buttonBackgroundColor", this.buttonBackgroundColor ? this.buttonBackgroundColor : '#212322');
                formData.append("buttonTextColor", this.buttonTextColor ? this.buttonTextColor : '#FFFFFF');
                formData.append("startDate", new Date(this.startDate));
                formData.append("endDate", new Date(this.endDate));
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.cms.home.homeSliderURL,
                    data: formData,
                    headers: {
                        "Authorization": "Bearer " + token,
                        "Content-Type": "multipart/form-data"
                    }
                };
                await this.$axios(config).then(async (response) => {
                    if (response.status == 201) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                    }
                    this.titleOne = '';
                    this.titleTwo = '';
                    this.titleOneColor = null;
                    this.titleTwoColor = null;
                    this.description = '';
                    this.descriptionFontColor = '';
                    this.buttonText = '';
                    this.buttonLink = '';
                    this.imageFile = '';
                    this.openNowButtonText = '';
                    this.openNowButtonFile = '';
                    this.showOpenNowButton = false;
                    this.imageFile = '';
                    this.previewImage = '';
                    this.$refs.imageFile.value = null;
                    this.$refs.openButtonFile.value = null;
                    this.startDate = undefined;
                    this.endDate = undefined;
                    this.dataList = await this.getDataList(this.setURL);
                    document.getElementById('addCloseButton').click();
                    let element = document.getElementsByClassName('lv-colorpicker__colorblock');
                    element.forEach(el => {
                        el.style.backgroundColor = '';
                    })
                }).catch(error => {
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        },
        async editContent(data) {
            this.dataLoaded = false;
            this.singleContent = {
                titleOne: '',
                titleTwo: '',
                titleOneColor: "",
                titleTwoColor: "",
                description: '',
                descriptionFontColor: '',
                buttonText: '',
                buttonLink: '',
                openNowButtonText: '',
                openNowButtonFile: '',
                showOpenNowButton: false,
                imageFile: '',
                mobileImage: "",
                buttonBackgroundColor: '',
                buttonTextColor: '',
                hasFile: false,
                startDate: undefined,
                endDate: undefined,
            };
            this.singleContent = await data;
            if (this.singleContent.showOpenNowButton == 1) {
                this.singleContent.showOpenNowButton = true;
            } else {
                this.singleContent.showOpenNowButton = false;
            }
            if (this.singleContent.openNowButtonFile) {
                this.singleContent.hasFile = true;
            }
            this.singleContent.imageFile = data.image;
            this.singleContent.mobileImage = data.mobileImage;

            const originalDateString = this.singleContent.startDate;
            const originalDate = new Date(originalDateString);
            const year = originalDate.getFullYear();
            const month = String(originalDate.getMonth() + 1).padStart(2, '0');
            const day = String(originalDate.getDate()).padStart(2, '0');
            this.singleContent.startDate = `${year}-${month}-${day}`;

            const originalDateString2 = this.singleContent.endDate;
            const originalDate2 = new Date(originalDateString2);
            const year2 = originalDate2.getFullYear();
            const month2 = String(originalDate2.getMonth() + 1).padStart(2, '0');
            const day2 = String(originalDate2.getDate()).padStart(2, '0');
            this.singleContent.endDate = `${year2}-${month2}-${day2}`;

            // setTimeout(() => {
            //     let element = document.getElementsByClassName('lv-colorpicker__colorblock');
            //     element[0].style.backgroundColor = this.singleContent.titleOneColor;
            //     element[1].style.backgroundColor = this.singleContent.titleTwoColor;
            // }, 2000);
            this.dataLoaded = true;
        },
        onImageUpdateChange(event) {
            this.singleContent.imageFile = event.target.files[0];
            let input = this.$refs.imagUpdateeFile;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewUpdateImage = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        onImageUpdateChangeMobile(event) {
            this.singleContent.mobileImage = event.target.files[0];
            let input = this.$refs.mobileImageUpdate;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewImageMobileUpdate = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        handleUpdateUploadFile(event) {
            this.singleContent.openNowButtonFile = event.target.files[0];
        },
        handleUpdateStatus(e) {
            this.singleContent.showOpenNowButton = e.target.checked;
        },
        update: async function () {
            let token = localStorage.getItem('token');
            const formData = new FormData();
            // formData.append("stateCode", this.stateCode);
            // formData.append("stateName", this.stateName);
            formData.append("titleOne", this.singleContent.titleOne);
            formData.append("titleTwo", this.singleContent.titleTwo);
            formData.append("titleOneColor", this.singleContent.titleOneColor ? this.singleContent.titleOneColor : '#212529');
            formData.append("titleTwoColor", this.singleContent.titleTwoColor ? this.singleContent.titleTwoColor : '#212529');
            formData.append("description", this.singleContent.description);
            formData.append("descriptionFontColor", this.singleContent.descriptionFontColor ? this.singleContent.descriptionFontColor : '#FFFFFF');
            formData.append("buttonText", this.singleContent.buttonText);
            formData.append("buttonLink", this.singleContent.buttonLink);
            formData.append("openNowButtonText", this.singleContent.openNowButtonText);
            formData.append("openNowButtonFile", this.singleContent.openNowButtonFile);
            formData.append("showOpenNowButton", this.singleContent.showOpenNowButton ? 1 : 0);
            formData.append("image", this.singleContent.imageFile);
            formData.append("mobileImage", this.singleContent.mobileImage);
            formData.append("buttonBackgroundColor", this.singleContent.buttonBackgroundColor ? this.singleContent.buttonBackgroundColor : "#212322");
            formData.append("buttonTextColor", this.singleContent.buttonTextColor ? this.singleContent.buttonTextColor : "#FFFFFF");
            formData.append("startDate", new Date(this.singleContent.startDate));
            formData.append("endDate", new Date(this.singleContent.endDate));

            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.cms.home.homeSliderURL + '/' + this.singleContent.id,
                data: formData,
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "multipart/form-data"
                }
            };
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                this.singleContent = {
                    titleOne: '',
                    titleTwo: '',
                    description: '',
                    descriptionFontColor: '',
                    buttonText: '',
                    buttonLink: '',
                    openNowButtonText: '',
                    openNowButtonFile: '',
                    showOpenNowButton: false,
                    imageFile: '',
                    buttonBackgroundColor: '',
                    buttonTextColor: '',
                    hasFile: false,
                    startDate: undefined,
                    endDate: undefined,
                }
                this.previewUpdateImage = "";
                this.$refs.imagUpdateeFile.value = null;
                this.$refs.updateOpenButtonFile.value = null;
                this.dataList = await this.getDataList(this.setURL);
                document.getElementById('editCloseButton').click();
            }).catch(error => {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        async handleSetDefault() {
            let token = localStorage.getItem('token');
            let data = {
                stateCode: this.stateCode
            }
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.homeSlider.setDefault,
                data: data,
                headers: {
                    "Authorization": "Bearer " + token,
                }
            };
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                this.dataList = await this.getDataList(this.setURL + '?stateCode=' + this.stateCode);
            }).catch(error => {
                console.log(error);
            });
        },
        addCss() {
            document.querySelector(".lv-overlaypanel").style.zIndex = 20000;
        },
        deleteContent: function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.setURL + '/' + id,
                            headers: authHeader()
                        }
                        await this.deleteData(config);
                        this.dataList = await this.getDataList(this.setURL);
                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    }
                }
            })

        },
        async updateOrder() {
            try {
                const newOrderList = this.tempDataList.map((el, index) => {
                    return { id: el.id, position: index + 1 }
                    
                })
                let config = {
                    method: 'POST',
                    url: this.setURL + '/slider-position',
                    headers: authHeader(),
                    data: {
                        newOrderList
                    },
                }
                await this.postData(config).then(async (response) => {
                    if (response.statusCode == 200) {
                        this.dataList = await this.getDataList(this.setURL);
                        this.tempDataList = this.dataList;
                    }
                });
            } catch (error) {
                console.log(error);
            }
        },
        async onDragUpdate(event) {
            event;
            await this.updateOrder();
        },
    }
}