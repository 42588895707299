<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb mb-0 p-0">
                        <li class="breadcrumb-item">
                            <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Customer List
                        </li>
                    </ol>
                </nav>
                <div class="ms-auto">
                    <div class="btn-group">
                        <button type="button" class="btn btn-primary me-2" data-bs-toggle="modal" data-bs-target="#addModal">
                            <i class="bx bxs-plus-square"></i>Add New Customer
                        </button>
                        <a role="button" class="btn btn-warning text-white" style="float: right" @click="exportUsers()">
                            <i class="fa-solid fa-file-export"></i>Export
                        </a>
                    </div>
                    <!-- Add Modal -->
                    <div class="modal fade" id="addModal" tabindex="-1" aria-labelledby="addModalLabel" aria-hidden="true">
                        <form>
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="addModalLabel">
                                            Add Customer
                                        </h5>
                                        <button type="button" id="addButton" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="row ms-1">
                                        <div class="modal-body col-md-6">
                                            <label class="form-label">First Name:<span style="color: red; font-size: 18px">*</span></label>
                                            <input class="form-control mb-1" type="text" v-model="firstName" placeholder="Enter first name" aria-label="default input example" />
                                        </div>
                                        <div class="modal-body col-md-6">
                                            <label class="form-label">Last Name:<span style="color: red; font-size: 18px">*</span></label>
                                            <input class="form-control mb-1" type="text" v-model="lastName" placeholder="Enter last name" aria-label="default input example" />
                                        </div>
                                    </div>
                                    <div class="row ms-1">
                                        <div class="modal-body col-md-6">
                                            <label class="form-label">Email:<span style="color: red; font-size: 18px">*</span></label>
                                            <input class="form-control mb-1" type="email" v-model="email" placeholder="Enter email" aria-label="default input example" />
                                        </div>
                                        <div class="modal-body col-md-6">
                                            <label class="form-label">Phone No:<span style="color: red; font-size: 18px">*</span></label>
                                            <input class="form-control mb-1" type="tel" v-model="phone" placeholder="Enter phone number" aria-label="default input example" />
                                        </div>
                                    </div>
                                    <div class="row ms-1">
                                        <div class="modal-body col-md-6">
                                            <label class="form-label">Password:<span style="color: red; font-size: 18px">*</span></label>
                                            <div class="input-group" id="show_hide_password">
                                                <input :type="inputTypePassword" v-model="password" class="form-control border-end-0" id="inputPassword" placeholder="Enter password" />
                                                <a href="javascript:void(0);" @click="changePasswordType()" class="input-group-text bg-transparent">
                                                    <i :class="iconStatus ? 'bx bx-hide' : 'bx bx-show'"></i>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="modal-body col-md-6">
                                            <label class="form-label">Confirm Password:<span style="color: red; font-size: 18px">*</span></label>
                                            <div class="input-group" id="show_hide_password">
                                                <input :type="inputTypeConfirmPassword" v-model="confirmPassword" class="form-control border-end-0" id="inputConfirmPassword" placeholder="Enter confirm password" />
                                                <a href="javascript:void(0);" @click="changeConfirmPasswordType()" class="input-group-text bg-transparent">
                                                    <i :class="iconFlag ? 'bx bx-hide' : 'bx bx-show'"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                                            Close
                                        </button>
                                        <button type="button" @click="addCustomer" class="btn btn-primary">
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <!-- Add Modal -->
                    <!-- Edit Modal -->
                    <div class="modal fade" id="editModal" tabindex="-1" aria-labelledby="editModalLabel"
                        aria-hidden="true">
                        <form @submit.prevent="updateCustomer">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="editModalLabel">
                                            Update Customer
                                        </h5>
                                        <button type="button" id="updateButton" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="row ms-1">
                                        <div class="modal-body col-md-6">
                                            <label class="form-label">First Name:</label>
                                            <input class="form-control mb-1" type="text" v-model="singleCustomer.firstName" placeholder="Enter first name" aria-label="default input example" />
                                        </div>
                                        <div class="modal-body col-md-6">
                                            <label class="form-label">Last Name:</label>
                                            <input class="form-control mb-1" type="text" v-model="singleCustomer.lastName" placeholder="Enter last name" aria-label="default input example" />
                                        </div>
                                    </div>
                                    <div class="row ms-1">
                                        <div class="modal-body col-md-6">
                                            <label class="form-label">Email:</label>
                                            <input class="form-control mb-1" type="email" v-model="singleCustomer.email" placeholder="Enter email" aria-label="default input example" readonly />
                                        </div>
                                        <div class="modal-body col-md-6">
                                            <label class="form-label">Phone No:</label>
                                            <input class="form-control mb-1" type="tel" v-model="singleCustomer.phone" placeholder="Enter phone number" aria-label="default input example" />
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                                            Close
                                        </button>
                                        <button type="submit" class="btn btn-primary">
                                            Update
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <!-- Edit Modal -->
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <h6 class="mb-0 text-uppercase">Customer List</h6>
                </div>
            </div>
            <hr />
            <div class="card">
                <div class="card-body">
                    <div class="table-responsive">
                        <table id="example" class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>SL</th>
                                    <th>Full Name</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>User Type</th>
                                    <th>Created At</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, index) in dataList" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ data.firstName + ' ' + data.lastName }}</td>
                                    <td>{{ data.email }}</td>
                                    <td>{{ data.phone }}</td>
                                    <td>{{ data.userType }}</td>
                                    <td>{{ $filters.formatDate(data.createdAt) }}</td>
                                    <td>
                                        <div class="d-flex order-actions">
                                            <a href="javascript:void(0);" v-on:click="editCustomer(data)" data-bs-toggle="modal" data-bs-target="#editModal" class="edit-button">
                                                <i class="bx bxs-edit text-white"></i>
                                            </a>
                                            <a href="javascript:void(0);" v-on:click="deleteCustomer(data.id)" class="ms-3 delete-button">
                                                <i class="bx bxs-trash text-white"></i>
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>SL</th>
                                    <th>Full Name</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>User Type</th>
                                    <th>Created At</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src='../js/customer.js'></script>

<style scoped>
.edit-button {
    background-color: #17a00e;
}

.delete-button {
    background-color: #f41127;
}
</style>