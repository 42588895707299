export default{
    name: "Header",
    data() {
        return {
            name:'',
            email:''
        }
    },
    mounted() {
        this.name = localStorage.getItem("name");
        this.email = localStorage.getItem("email");
        window.$("#menu").metisMenu();
    },
    methods: {
        logout() {
            localStorage.clear();
            this.$router.push('/admin-login');
            window.location.reload(true);
        }
    }
}