import LvColorpicker from 'lightvue/color-picker';

export default {
    components: {
        LvColorpicker
    },
    data() {
        return {
            url: this.$serverURL + this.$api.componentUI.homePageURL,
            cmsData: {},
            dataLoaded: false,
            colors: [
                "#E91E63",
                "#F44336", 
                "#9C27B0", 
                "#673AB7", 
                "#3F51B5", 
                "#2196F3", 
                "#03A9F4", 
                "#00BCD4", 
                "#009688", 
                "#4CAF50", 
                "#8BC34A", 
                "#CDDC39", 
                "#FFEB3B", 
                "#FFC107", 
                "#FF9800", 
                "#795548" 
            ]
        }
    },
    async created() {
        document.title = "JARS - Home Page";
        await this.getData();
        this.dataLoaded = true;
    },
    methods: {
        async getData() {
            let response = await this.getCmsData(this.url);
            if (response.statusCode == 200) {
                this.cmsData = (response.data.length > 0) ? response.data[0] : {};
            }
            else {
                this.cmsData = {};
            }
        },
        async update() {
            let c = confirm("Are you sure to change the data?");
            if(c){
                try {
                    let data = {
                        pageBackgroundColor: this.cmsData.pageBackgroundColor ? this.cmsData.pageBackgroundColor : '#FFFFFF',

                        highFiveSectionBackgroundColor: this.cmsData.highFiveSectionBackgroundColor ? this.cmsData.highFiveSectionBackgroundColor : '#212322',
                        highFiveSectionTitleFontColor: this.cmsData.highFiveSectionTitleFontColor ? this.cmsData.highFiveSectionTitleFontColor : '#FFFFFF',
                        highFiveSectionSubtitleFontColor: this.cmsData.highFiveSectionSubtitleFontColor ? this.cmsData.highFiveSectionSubtitleFontColor : '#FFFFFF',
                        highFiveSectionButtonBackgroundColor: this.cmsData.highFiveSectionButtonBackgroundColor ? this.cmsData.highFiveSectionButtonBackgroundColor : '#FFFFFF',
                        highFiveSectionButtonFontColor: this.cmsData.highFiveSectionButtonFontColor ? this.cmsData.highFiveSectionButtonFontColor : '#212322',
                        highFiveSectionButtonBorderColor: this.cmsData.highFiveSectionButtonBorderColor ? this.cmsData.highFiveSectionButtonBorderColor : '#FFFFFF',

                        shopByPriceSectionBackgroundColor: this.cmsData.shopByPriceSectionBackgroundColor ? this.cmsData.shopByPriceSectionBackgroundColor : '#212322',
                        shopByPriceSectionTitleFontColor: this.cmsData.shopByPriceSectionTitleFontColor ? this.cmsData.shopByPriceSectionTitleFontColor : '#FFFFFF',
                        shopByPriceSectionSubtitleFontColor: this.cmsData.shopByPriceSectionSubtitleFontColor ? this.cmsData.shopByPriceSectionSubtitleFontColor : '#FFFFFF',
                        shopByPriceSectionButtonBackgroundColor: this.cmsData.shopByPriceSectionButtonBackgroundColor ? this.cmsData.shopByPriceSectionButtonBackgroundColor : '#FFFFFF',
                        shopByPriceSectionButtonFontColor: this.cmsData.shopByPriceSectionButtonFontColor ? this.cmsData.shopByPriceSectionButtonFontColor : '#212322',
                        shopByPriceSectionButtonBorderColor: this.cmsData.shopByPriceSectionButtonBorderColor ? this.cmsData.shopByPriceSectionButtonBorderColor : '#FFFFFF',

                        newDropsSectionBackgroundColor: this.cmsData.newDropsSectionBackgroundColor ? this.cmsData.newDropsSectionBackgroundColor : '#212322',
                        newDropsSectionTitleFontColor: this.cmsData.newDropsSectionTitleFontColor ? this.cmsData.newDropsSectionTitleFontColor : '#FFFFFF',
                        newDropsSectionSubtitleFontColor: this.cmsData.newDropsSectionSubtitleFontColor ? this.cmsData.newDropsSectionSubtitleFontColor : '#FFFFFF',
                        newDropsSectionButtonFontColor: this.cmsData.newDropsSectionButtonFontColor ? this.cmsData.newDropsSectionButtonFontColor : '#FFFFFF',
                        newDropsSectionButtonBackgroundColor: this.cmsData.newDropsSectionButtonBackgroundColor ? this.cmsData.newDropsSectionButtonBackgroundColor : '#FFFFFF',
                        newDropsSectionButtonMobileFontColor: this.cmsData.newDropsSectionButtonMobileFontColor ? this.cmsData.newDropsSectionButtonMobileFontColor : '#212322',
                        newDropsSectionButtonBorderColor: this.cmsData.newDropsSectionButtonBorderColor ? this.cmsData.newDropsSectionButtonBorderColor : '#FFFFFF',

                        higherSelfSectionBackgroundColor: this.cmsData.higherSelfSectionBackgroundColor ? this.cmsData.higherSelfSectionBackgroundColor : '#212322',
                        higherSelfSectionTitleFontColor: this.cmsData.higherSelfSectionTitleFontColor ? this.cmsData.higherSelfSectionTitleFontColor : '#FFFFFF',
                        higherSelfSectionSubtitleFontColor: this.cmsData.higherSelfSectionSubtitleFontColor ? this.cmsData.higherSelfSectionSubtitleFontColor : '#FFFFFF',
                        higherSelfSectionButtonFontColor: this.cmsData.higherSelfSectionButtonFontColor ? this.cmsData.higherSelfSectionButtonFontColor : '#FFFFFF',

                        higherSelfSectionCardOneBackgroundColor: this.cmsData.higherSelfSectionCardOneBackgroundColor ? this.cmsData.higherSelfSectionCardOneBackgroundColor : '#FFFFFF',
                        higherSelfSectionCardOneTitleFontColor: this.cmsData.higherSelfSectionCardOneTitleFontColor ? this.cmsData.higherSelfSectionCardOneTitleFontColor : '#212529',
                        higherSelfSectionCardOneSubtitleFontColor: this.cmsData.higherSelfSectionCardOneSubtitleFontColor ? this.cmsData.higherSelfSectionCardOneSubtitleFontColor : '#212529',
                        
                        higherSelfSectionCardTwoBackgroundColor: this.cmsData.higherSelfSectionCardTwoBackgroundColor ? this.cmsData.higherSelfSectionCardTwoBackgroundColor : '#FFFFFF',
                        higherSelfSectionCardTwoTitleFontColor: this.cmsData.higherSelfSectionCardTwoTitleFontColor ? this.cmsData.higherSelfSectionCardTwoTitleFontColor : '#212529',
                        higherSelfSectionCardTwoSubtitleFontColor: this.cmsData.higherSelfSectionCardTwoSubtitleFontColor ? this.cmsData.higherSelfSectionCardTwoSubtitleFontColor : '#212529',
                        
                        higherSelfSectionCardThreeBackgroundColor: this.cmsData.higherSelfSectionCardThreeBackgroundColor ? this.cmsData.higherSelfSectionCardThreeBackgroundColor : '#FFFFFF',
                        higherSelfSectionCardThreeTitleFontColor: this.cmsData.higherSelfSectionCardThreeTitleFontColor ? this.cmsData.higherSelfSectionCardThreeTitleFontColor : '#212529',
                        higherSelfSectionCardThreeSubtitleFontColor: this.cmsData.higherSelfSectionCardThreeSubtitleFontColor ? this.cmsData.higherSelfSectionCardThreeSubtitleFontColor : '#212529',
                        
                        higherSelfSectionCardFourBackgroundColor: this.cmsData.higherSelfSectionCardFourBackgroundColor ? this.cmsData.higherSelfSectionCardFourBackgroundColor : '#FFFFFF',
                        higherSelfSectionCardFourTitleFontColor: this.cmsData.higherSelfSectionCardFourTitleFontColor ? this.cmsData.higherSelfSectionCardFourTitleFontColor : '#212529',
                        higherSelfSectionCardFourSubtitleFontColor: this.cmsData.higherSelfSectionCardFourSubtitleFontColor ? this.cmsData.higherSelfSectionCardFourSubtitleFontColor : '#212529',
                        
                        higherSelfSectionCardButtonBackgroundColor: this.cmsData.higherSelfSectionCardButtonBackgroundColor ? this.cmsData.higherSelfSectionCardButtonBackgroundColor : '#FFFFFF',
                        higherSelfSectionCardButtonFontColor: this.cmsData.higherSelfSectionCardButtonFontColor ? this.cmsData.higherSelfSectionCardButtonFontColor : '#212529',
                        higherSelfSectionCardButtonBorderColor: this.cmsData.higherSelfSectionCardButtonBorderColor ? this.cmsData.higherSelfSectionCardButtonBorderColor : '#212529',
                        higherSelfSectionCardButtonHoverBackgroundColor: this.cmsData.higherSelfSectionCardButtonHoverBackgroundColor ? this.cmsData.higherSelfSectionCardButtonHoverBackgroundColor : '#212529',
                        higherSelfSectionCardButtonHoverFontColor: this.cmsData.higherSelfSectionCardButtonHoverFontColor ? this.cmsData.higherSelfSectionCardButtonHoverFontColor : '#FFFFFF',
                        higherSelfSectionCardButtonHoverBorderColor: this.cmsData.higherSelfSectionCardButtonHoverBorderColor ? this.cmsData.higherSelfSectionCardButtonHoverBorderColor : '#212529',

                        sunnyDazeSectionBackgroundColor: this.cmsData.sunnyDazeSectionBackgroundColor ? this.cmsData.sunnyDazeSectionBackgroundColor : '#212322',
                        sunnyDazeSectionTitleFontColor: this.cmsData.sunnyDazeSectionTitleFontColor ? this.cmsData.sunnyDazeSectionTitleFontColor : '#FFFFFF',
                        sunnyDazeSectionSubtitleFontColor: this.cmsData.sunnyDazeSectionSubtitleFontColor ? this.cmsData.sunnyDazeSectionSubtitleFontColor : '#FFFFFF',
                        sunnyDazeSectionButtonFontColor: this.cmsData.sunnyDazeSectionButtonFontColor ? this.cmsData.sunnyDazeSectionButtonFontColor : '#FFFFFF',
                        sunnyDazeSectionButtonBackgroundColor: this.cmsData.sunnyDazeSectionButtonBackgroundColor ? this.cmsData.sunnyDazeSectionButtonBackgroundColor : '#FFFFFF',
                        sunnyDazeSectionButtonMobileFontColor: this.cmsData.sunnyDazeSectionButtonMobileFontColor ? this.cmsData.sunnyDazeSectionButtonMobileFontColor : '#212322',
                        sunnyDazeSectionButtonBorderColor: this.cmsData.sunnyDazeSectionButtonBorderColor ? this.cmsData.sunnyDazeSectionButtonBorderColor : '#FFFFFF',
                        
                        shopOurInventorySectionBackgroundColor: this.cmsData.shopOurInventorySectionBackgroundColor ? this.cmsData.shopOurInventorySectionBackgroundColor : '#212322',
                        shopOurInventorySectionTitleFontColor: this.cmsData.shopOurInventorySectionTitleFontColor ? this.cmsData.shopOurInventorySectionTitleFontColor : '#FFFFFF',
                        shopOurInventorySectionSubtitleFontColor: this.cmsData.shopOurInventorySectionSubtitleFontColor ? this.cmsData.shopOurInventorySectionSubtitleFontColor : '#FFFFFF',
                    }
                    this.$swal.showLoading();
                    let response = await this.createUpdateCMS(this.url, data);
                    if (response.statusCode == 200 || response.statusCode == 201) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                    }
                    await this.getData();
                } catch (error) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!"
                    });
                } finally {
                    this.$swal.hideLoading();
                }
            }
        }
    }
}