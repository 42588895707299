<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Page Schema</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item"><router-link to="/dashboard"><i class="bx bx-home-alt"></i></router-link></li>
                            <li class="breadcrumb-item active" aria-current="page">Page Schema</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Page Schema</h5>
                    <hr/>
                    <div class="form-body mt-4">
                        <div class="row">
                            <div class="col-md-10 col-12">
                                <div class="border border-3 p-4 rounded">                                    
                                    <div class="mb-3">
                                        <label class="form-label">Home Page Schema</label>
                                        <textarea class="form-control" v-model="homePage" cols="30" rows="10" placeholder="home schema"></textarea>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Shop Page Schema</label>
                                        <textarea class="form-control" v-model="shopPage" cols="30" rows="10" placeholder="shop schema"></textarea>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Category Page Schema</label>
                                        <textarea class="form-control" v-model="categoryPage" cols="30" rows="10" placeholder="category schema"></textarea>
                                    </div>
                                    <div class="d-flex justify-content-end">
                                        <button @click="updatePageSchema" type="button" class="btn btn-primary">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/page-schema.js"></script>