<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Landing Page CMS</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/dashboard">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Landing Page CMS</li>
            </ol>
          </nav>
        </div>
      </div>
      <form @submit.prevent="updateCms()">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Landing Page CMS</h5>
            <hr />
            <div class="form-body mt-4">
              <div class="row">
                <div class="col-lg-12 mb-3">
                  <div class="border border-3 p-4 rounded">
                    <div class="row">
                      <div class="col-6 mb-3">
                        <label class="form-label">Title:</label>
                        <input
                          type="text"
                          v-model="cmsData.title"
                          class="form-control"
                          id="inputPageHeaderTitle"
                          placeholder="Enter title"
                        />
                      </div>
                      <div class="col-6 mb-3">
                        <label class="form-label">Question Title:</label>
                        <input
                          type="text"
                          v-model="cmsData.questionTitle"
                          class="form-control"
                          id="inputPageHeaderPageName"
                          placeholder="Enter question title"
                        />
                      </div>

                      <div class="col-6 mb-3">
                        <label class="form-label">Button One Text:</label>
                        <input
                          type="text"
                          v-model="cmsData.buttonOneText"
                          class="form-control"
                          id="inputPageHeaderPageName"
                          placeholder="Enter button one text"
                        />
                      </div>
                      <div class="col-6 mb-3">
                        <label class="form-label">Button Two Text:</label>
                        <input
                          type="text"
                          v-model="cmsData.buttonTwoText"
                          class="form-control"
                          id="inputPageHeaderPageName"
                          placeholder="Enter button two text"
                        />
                      </div>

                      <div class="mb-3">
                        <label for="exampleFormControlTextarea1" class="form-label">Description:</label>
                        <textarea
                          class="form-control"
                          id="exampleFormControlTextarea1"
                          rows="3"
                          v-model="cmsData.description"
                        ></textarea>
                      </div>

                      <div class="col-6 mb-3">
                        <label class="form-label">Pickup Page Title:</label>
                        <input
                          type="text"
                          v-model="cmsData.pickUpPageTitle"
                          class="form-control"
                          id="inputPageHeaderPageName"
                          placeholder="Enter pickup page title"
                        />
                      </div>
                      <div class="col-6 mb-3">
                        <label class="form-label">Delivery Page Title:</label>
                        <input
                          type="text"
                          v-model="cmsData.deliveryPageTitle"
                          class="form-control"
                          id="inputPageHeaderPageName"
                          placeholder="Enter delivery page title"
                        />
                      </div>

                      <div class="col-6 mb-3">
                        <label class="form-label">Search Box Text:</label>
                        <input
                          type="text"
                          v-model="cmsData.searchBoxText"
                          class="form-control"
                          id="inputPageHeaderPageName"
                          placeholder="Enter search box text"
                        />
                      </div>
                      <div class="col-6 mb-3">
                        <label class="form-label">Choose Store Button Text:</label>
                        <input
                          type="text"
                          v-model="cmsData.chooseStoreButtonText"
                          class="form-control"
                          id="inputPageHeaderPageName"
                          placeholder="Enter store button text"
                        />
                      </div>

                      <div class="col-6 mb-3">
                        <div class="row">
                          <div class="col-12">
                            <label for="inputProductTitle" class="form-label">
                              Logo:
                              <strong>
                                [Preferred Image Size: 73X73, Max Upload Limit:
                                1MB]
                              </strong>
                            </label>
                          </div>
                          <div class="col-12">
                            <img
                              style="position: relative; left: 11px"
                              v-if="preview.imageOne"
                              :src="preview.imageOne"
                              height="70"
                              width="100"
                            />
                            <img
                              style="position: relative; left: 11px"
                              v-else-if="cmsData.logo"
                              :src="cmsData.logo"
                              height="70"
                              width="100"
                            />
                          </div>
                        </div>

                        <input
                          class="form-control mt-3"
                          type="file"
                          ref="backgroundImage1"
                          accept="image/*"
                          @change="handleUploadImage('logo','imageOne','backgroundImage1', $event)"
                        />
                      </div>
                      <div class="col-6 mb-3">
                        <div class="row">
                          <div class="col-12">
                            <label for="inputProductTitle" class="form-label">
                              Image:
                              <strong>
                                [Preferred Image Size: 1366X587, Max Upload Limit:
                                1MB]
                              </strong>
                            </label>
                          </div>
                          <div class="col-12">
                            <img
                              style="position: relative; left: 11px"
                              v-if="preview.imageTwo"
                              :src="preview.imageTwo"
                              height="70"
                              width="100"
                            />
                            <img
                              style="position: relative; left: 11px"
                              v-else-if="cmsData.image"
                              :src="cmsData.image"
                              height="70"
                              width="100"
                            />
                          </div>
                        </div>

                        <input
                          class="form-control mt-3"
                          type="file"
                          ref="backgroundImage2"
                          accept="image/*"
                          @change="handleUploadImage('image','imageTwo','backgroundImage2', $event)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-grid">
                  <button type="submit" class="btn btn-primary">Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script src="../js/landing-page.js"></script>