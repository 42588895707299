<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Career</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/home">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Career</li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="card">
        <div class="card-body p-4">
          <h5 class="card-title">Add New Career</h5>
          <hr />
          <div class="form-body mt-4">
            <div class="row">
              <div class="col-lg-8">
                <div class="border border-3 p-4 rounded">
                  <div class="row mb-3">
                    <div class="col-sm-6">
                      <label class="form-label">Title:</label>
                      <input type="text" v-model="title" class="form-control" />
                    </div>
                    <div class="col-sm-6">
                      <label class="form-label">Job Type:</label>
                      <!-- <input type="text" v-model="jobType" class="form-control" /> -->
                      <select
                        v-model="jobType"
                        class="form-select form-control"
                      >
                        <option
                          class="p-1"
                          v-for="(data, index) in jobTypeList"
                          :key="index"
                          :value="data"
                        >{{ data }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-sm-6">
                      <label class="form-label">Store:</label>
                      <select
                        v-model="selectedStore"
                        class="form-select form-control"
                        @change="handleAddress(selectedStore)"
                      >
                        <option
                          class="p-1"
                          v-for="(data, index) in storeList"
                          :key="index"
                          :value="data"
                        >{{ data.name }}</option>
                      </select>
                    </div>
                    <div class="col-sm-6">
                      <label class="form-label">Location:</label>
                      <input type="text" v-model="location" class="form-control" />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-sm-6">
                      <label class="form-label">City:</label>
                      <input type="text" v-model="city" class="form-control" />
                    </div>
                    <div class="col-sm-6">
                      <label class="form-label">State:</label>
                      <select v-model="state" class="form-select form-control">
                        <option
                          class="p-1"
                          v-for="(data, index) in stateList"
                          :key="index"
                          :value="data"
                        >{{ data.name }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Short Description:</label>
                    <textarea class="form-control" v-model="shortDescription" />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Description:</label>
                    <ckeditor :editor="editor" v-model="description" :config="editorConfig"></ckeditor>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Salary:</label>
                    <input type="text" v-model="salary" class="form-control" />
                  </div>
                  <div class="d-grid">
                    <button v-on:click="save" type="button" class="btn btn-primary">Create</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/add-career.js"></script>