<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Header CMS</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/home">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Header CMS</li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Header CMS</h5>
          <hr />
          <div class="form-body mt-4">
            <div class="row">
              <div class="col-lg-8">
                <div class="border border-3 p-4 rounded">
                  <!-- <div class="mb-3">
                    <label class="form-label">Banner Text:</label>
                    <textarea
                      class="form-control"
                      v-model="cmsData.bannerText"
                      aria-label="default input example"
                    />
                  </div> -->
                  <div class="mb-3">
                    <label class="form-label">Header Text:</label>
                    <input type="text" v-model="cmsData.headerText" class="form-control" />
                  </div>
                  <div class="mb-3">
                    <label for="inputProductTitle" class="form-label">
                      Logo:
                      <strong>
                        [Preferred Image Size: 40X40, Max Upload Limit:
                        1MB]
                      </strong>
                    </label>
                    <img
                      style="position: relative; left: 11px"
                      v-if="previewLogo"
                      :src="previewLogo"
                      height="70"
                      width="100"
                    />
                    <img
                      style="position: relative; left: 11px"
                      v-else-if="cmsData.logo"
                      :src="cmsData.logo"
                      height="70"
                      width="100"
                    />
                    <img
                      style="position: relative; left: 11px"
                      v-else
                      src="../../../../assets/images/default.png"
                      height="70"
                      width="100"
                    />
                    <input
                      class="form-control mt-3"
                      type="file"
                      ref="logo"
                      accept="image/*"
                      @change="uploadLogo"
                    />
                  </div>
                  <div class="d-grid">
                    <button v-on:click="update()" type="button" class="btn btn-primary">Save</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/header-cms.js"></script>