import LvColorpicker from 'lightvue/color-picker';

export default {
    components: {
        LvColorpicker
    },
    data() {
        return {
            url: this.$serverURL + this.$api.cms.home.sectionSevenCmsURL,
            // stateCode: this.$route.query.stateCode,
            // stateName: this.$route.query.stateName,
            cmsData: {
                image: '',
                bgColor: '',
                title: '',
                subTitle: '',
                buttonText: '',
                buttonLink: '',
                isImageOrColor: '',
                buttonBgColor : '',
                buttonFontColor : ''
            },
            preview: {
                image: ""
            },
            colors: [
                "#E91E63",
                "#F44336",
                "#9C27B0",
                "#673AB7",
                "#3F51B5",
                "#2196F3",
                "#03A9F4",
                "#00BCD4",
                "#009688",
                "#4CAF50",
                "#8BC34A",
                "#CDDC39",
                "#FFEB3B",
                "#FFC107",
                "#FF9800",
                "#795548"
            ],
            isDataLoaded: false,
        }
    },
    async created() {
        document.title = "JARS - Section Seven CMS";
        await this.getData();
    },
    methods: {
        async getData() {
            let response = await this.getCmsData(this.url);
            if (response.statusCode == 200) {
                this.isDataLoaded = true;
                this.cmsData = (response.data.length > 0) ? response.data[0] : {};
            }
            else {
                this.cmsData = {};
            }
        },
        handleUploadImage(imageKey, previewImageKey, ref, event) {
            this.cmsData[imageKey] = event.target.files[0];
            let input = this.$refs[ref];
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.preview[previewImageKey] = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        async update() {
            try {
                let fromData = new FormData();
                fromData.append('image', this.cmsData.image);
                fromData.append('bgColor', this.cmsData.bgColor);
                fromData.append('title', this.cmsData.title);
                fromData.append('subTitle', this.cmsData.subTitle);
                fromData.append('buttonText', this.cmsData.buttonText);
                fromData.append('buttonLink', this.cmsData.buttonLink);
                fromData.append('isImageOrColor', this.cmsData.isImageOrColor);
                fromData.append('buttonFontColor', this.cmsData.buttonFontColor);
                fromData.append('buttonBgColor', this.cmsData.buttonBgColor);
                // fromData.append("stateCode", this.stateCode);
                // fromData.append("stateName", this.stateName);
                // fromData.append("isDefault", this.cmsData.isDefault ? this.cmsData.isDefault : false);
                this.$swal.showLoading();
                let response = await this.createUpdateCMS(this.url, fromData);
                if (response.statusCode == 200 || response.statusCode == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                await this.getData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            }finally {
                this.$swal.hideLoading();
            }
        }
    }
}