import { authHeader } from '../../../auth';

export default {
    name: "Store",
    data() {
        return {
            dataList: [],
            cmsData: {},
            setURL: this.$serverURL + this.$api.store.requestURL,
            cmsURL: this.$serverURL + this.$api.cms.storeCmsURL
        }
    },
    async created() {
        document.title = "JARS - Store";
        await this.getStoreDataList(this.setURL);
        await this.getData(this.cmsURL);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        getData: async function (url) {
            let response = await this.getCmsData(url);
            if (response.statusCode == 200) {
                this.cmsData = (response.data.length > 0) ? response.data[0] : {};
            }
            else {
                this.cmsData = {};
            }
        },
        getStoreDataList: async function (url) {
            try {
                let config = {
                    method: "POST",
                    url: url
                }
                await this.$axios(config).then((response) => {
                    if(response.status == 200) {
                        this.dataList = response.data; 
                    }
                }).catch(error => {
                    console.log(error);
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            }
        },
        update: function (data) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "This store will be selected!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Select'
                }).then(async(result) => {
                    if (result.isConfirmed) {
                        try {
                            let payload = {
                                retailerId: data.id,
                                name: data.name,
                                address: data.address
                            };
                            this.$swal.showLoading();
                            let response = await this.createUpdateCMS(this.cmsURL, payload);
                            if (response.statusCode == 200 || response.statusCode == 201) {
                                this.$swal.fire({
                                    toast: true,
                                    position: 'top-end',
                                    text: "Store selected successfully.",
                                    icon: "success",
                                    showConfirmButton: false,
                                    timer: 3000,
                                    animation: false,
                                    timerProgressBar: true,
                                    didOpen: (toast) => {
                                        toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                        toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                                    }
                                });
                            }
                            await this.getData(this.cmsURL);
                        } catch (error) {
                            this.$swal.fire({
                                icon: "error",
                                text: error.response.data.message
                            });
                        } finally {
                            this.$swal.hideLoading();
                        }
                    }
            })
        },
        deleteContent: function () {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "No store will be selected!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm'
                }).then(async(result) => {
                    if (result.isConfirmed) {
                        try {
                            this.$swal.showLoading();
                            let config = {
                                method: "DELETE",
                                url: this.cmsURL,
                                headers: authHeader()
                            };
                            await this.$axios(config).then((response) => {
                                if (response.data.statusCode == 200) {
                                    this.$swal.fire({
                                        toast: true,
                                        position: 'top-end',
                                        text: "Currently no store selected.",
                                        icon: "success",
                                        showConfirmButton: false,
                                        timer: 3000,
                                        animation: false,
                                        timerProgressBar: true,
                                        didOpen: (toast) => {
                                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                                        }
                                    });
                                }
                            }).catch(error => {
                                this.$swal.fire({
                                    icon: "error",
                                    text: error.response.data.message
                                });
                            });
                            await this.getData(this.cmsURL);
                        } catch (error) {
                            this.$swal.fire({
                                icon: "error",
                                text: error.response.data.message
                            });
                        } finally {
                            this.$swal.hideLoading();
                        }
                    }
            })
        }
    }
}