export default {
    data() {
        return {
            url: this.$serverURL + this.$api.cms.home.sectionNineCmsURL,
            // stateCode: this.$route.query.stateCode,
            // stateName: this.$route.query.stateName,
            cmsData: {}
        }
    },
    async created() {
        document.title = "JARS - Section Nine CMS";
        this.getData();
    },
    methods: {
        async getData() {
            let response = await this.getCmsData(this.url);
            if (response.statusCode == 200) {
                this.cmsData = (response.data.length > 0) ? response.data[0] : {};
            }
            else {
                this.cmsData = {};
            }
        },
        async update() {
            try {
                let data = {
                    titleOne: this.cmsData.titleOne,
                    titleTwo: this.cmsData.titleTwo,
                    subTitle: this.cmsData.subTitle,
                    // stateCode: this.stateCode || this.cmsData.stateCode,
                    // stateName: this.stateName || this.cmsData.stateName,
                    // isDefault: this.cmsData.isDefault ? this.cmsData.isDefault : false
                };
                this.$swal.showLoading();
                let response = await this.createUpdateCMS(this.url, data);
                if (response.statusCode == 200 || response.statusCode == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                await this.getData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        }
    }
}