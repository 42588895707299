import { authHeader } from "../../../auth";

export default {
    data() {
        return {
            dutchieURL: this.$serverURL + this.$api.synchronize.dutchieRequestURL,
            elasticURL: this.$serverURL + this.$api.synchronize.elasticRequestURL,
            retailerURL: this.$serverURL + this.$api.synchronize.retailerRequestURL,
            elasticRetailerURL: this.$serverURL + this.$api.synchronize.elasticRetailerRequestURL,
            retailerIHeartJaneURL: this.$serverURL + this.$api.synchronize.retailerIHeartJaneRequestURL,
            dutchieButtonText: "Synchronize",
            dutchieButtonIconClass: "fa-solid fa-rotate",
            dutchieButtonClass: "btn btn-primary mt-4",
            dutchieButtonFlag: false,
            elasticButtonText: "Synchronize",
            elasticButtonIconClass: "fa-solid fa-rotate",
            elasticButtonClass: "btn btn-primary mt-4",
            elasticButtonFlag: false,
            elasticRetailerButtonText: "Synchronize",
            elasticRetailerButtonIconClass: "fa-solid fa-rotate",
            elasticRetailerButtonClass: "btn btn-primary mt-4",
            elasticRetailerButtonFlag: false,
            retailerButtonText: "Synchronize",
            retailerButtonIconClass: "fa-solid fa-rotate",
            retailerButtonClass: "btn btn-primary mt-4",
            retailerButtonFlag: false,
            retailerIHeartJaneButtonText: "Synchronize",
            retailerIHeartJaneButtonIconClass: "fa-solid fa-rotate",
            retailerIHeartJaneButtonClass: "btn btn-primary mt-4",
            retailerIHeartJaneButtonFlag: false
        }
    },
    async created() {
        document.title = "JARS - Synchronize";
        await this.loadJS();
    },
    methods: {
        async loadJS() {
            var scripts = [
                "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
                "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
                "/core/assets/js/table-datatable.js",
                "/core/assets/plugins/metismenu/js/metisMenu.min.js",
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);
            });
        },
        async getDutchieSynchronizeData() {
            try {
                this.dutchieButtonText = "Synchronizing...";
                this.dutchieButtonIconClass = "fa-solid fa-rotate fa-spin";
                this.dutchieButtonClass = "btn btn-secondary mt-4";
                this.dutchieButtonFlag = true;
                let config = {
                    method: "GET",
                    url: this.dutchieURL,
                    headers: authHeader()
                }
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: 'Product synchronization completed successfully.',
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                        this.dutchieButtonText = "Synchronize";
                        this.dutchieButtonIconClass = "fa-solid fa-rotate";
                        this.dutchieButtonClass = "btn btn-primary mt-4";
                        this.dutchieButtonFlag = false;
                    }
                    else if (response.data.statusCode == 400) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: 'Product synchronization failed.',
                            icon: "error",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                        this.dutchieButtonText = "Synchronize";
                        this.dutchieButtonIconClass = "fa-solid fa-rotate";
                        this.dutchieButtonClass = "btn btn-primary mt-4";
                        this.dutchieButtonFlag = false;
                    }
                })
            }
            catch (error) {
                this.$swal.fire({
                    icon: "error", 
                    text: "Something went wrong. Please try again!"
                });
            }
        },
        async getRetailerSynchronizeData() {
            try {
                this.retailerButtonText = "Synchronizing...";
                this.retailerButtonIconClass = "fa-solid fa-rotate fa-spin";
                this.retailerButtonClass = "btn btn-secondary mt-4";
                this.retailerButtonFlag = true;
                let config = {
                    method: "GET",
                    url: this.retailerURL,
                    headers: authHeader()
                }
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: 'Retailer synchronization completed successfully.',
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                        this.retailerButtonText = "Synchronize";
                        this.retailerButtonIconClass = "fa-solid fa-rotate";
                        this.retailerButtonClass = "btn btn-primary mt-4";
                        this.retailerButtonFlag = false;
                    }
                    else if (response.data.statusCode == 400) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: 'Retailer synchronization failed.',
                            icon: "error",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                        this.retailerButtonText = "Synchronize";
                        this.retailerButtonIconClass = "fa-solid fa-rotate";
                        this.retailerButtonClass = "btn btn-primary mt-4";
                        this.retailerButtonFlag = false;
                    }
                })
            }
            catch (error) {
                this.$swal.fire({
                    icon: "error", 
                    text: "Something went wrong. Please try again!"
                });
            }
        },
        async getElasticSearchData() {
            try {
                this.elasticButtonText = "Synchronizing...";
                this.elasticButtonIconClass = "fa-solid fa-rotate fa-spin";
                this.elasticButtonClass = "btn btn-secondary mt-4";
                this.elasticButtonFlag = true;
                let config = {
                    method: "GET",
                    url: this.elasticURL,
                    headers: authHeader()
                }
                await this.$axios(config).then(async (response) => {
                    if (response.status == 200) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: 'Product synchronization completed successfully.',
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                        this.elasticButtonText = "Synchronize";
                        this.elasticButtonIconClass = "fa-solid fa-rotate";
                        this.elasticButtonClass = "btn btn-primary mt-4";
                        this.elasticButtonFlag = false;
                    }
                    else if (response.data.statusCode == 400) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: 'Product synchronization failed.',
                            icon: "error",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                        this.elasticButtonText = "Synchronize";
                        this.elasticButtonIconClass = "fa-solid fa-rotate";
                        this.elasticButtonClass = "btn btn-primary mt-4";
                        this.elasticButtonFlag = false;
                    }
                })
            }
            catch (error) {
                this.$swal.fire({
                    icon: "error", 
                    text: "Something went wrong. Please try again!"
                });
            }
        },
        async getRetailerElasticSearchData() {
            try {
                this.elasticRetailerButtonText = "Synchronizing...";
                this.elasticRetailerButtonIconClass = "fa-solid fa-rotate fa-spin";
                this.elasticRetailerButtonClass = "btn btn-secondary mt-4";
                this.elasticRetailerButtonFlag = true;
                let config = {
                    method: "GET",
                    url: this.elasticRetailerURL,
                    headers: authHeader()
                }
                await this.$axios(config).then(async (response) => {
                    if (response.status == 200) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: 'Elastic Retailer synchronization completed successfully.',
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                        this.elasticRetailerButtonText = "Synchronize";
                        this.elasticRetailerButtonIconClass = "fa-solid fa-rotate";
                        this.elasticRetailerButtonClass = "btn btn-primary mt-4";
                        this.elasticRetailerButtonFlag = false;
                    }
                    else if (response.data.statusCode == 400) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: 'Product synchronization failed.',
                            icon: "error",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                        this.elasticRetailerButtonText = "Synchronize";
                        this.elasticRetailerButtonIconClass = "fa-solid fa-rotate";
                        this.elasticRetailerButtonClass = "btn btn-primary mt-4";
                        this.elasticRetailerButtonFlag = false;
                    }
                })
            }
            catch (error) {
                this.$swal.fire({
                    icon: "error", 
                    text: "Something went wrong. Please try again!"
                });
            }
        },
        async getRetailerIHeartJaneSynchronizeData() {
            try {
                this.retailerIHeartJaneButtonText = "Synchronizing...";
                this.retailerIHeartJaneButtonIconClass = "fa-solid fa-rotate fa-spin";
                this.retailerIHeartJaneButtonClass = "btn btn-secondary mt-4";
                this.retailerIHeartJaneButtonFlag = true;
                let config = {
                    method: "GET",
                    url: this.retailerIHeartJaneURL,
                    headers: authHeader()
                }
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: 'Retailer synchronization completed successfully.',
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                        this.retailerIHeartJaneButtonText = "Synchronize";
                        this.retailerIHeartJaneButtonIconClass = "fa-solid fa-rotate";
                        this.retailerIHeartJaneButtonClass = "btn btn-primary mt-4";
                        this.retailerIHeartJaneButtonFlag = false;
                    }
                    else if (response.data.statusCode == 400) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: 'Retailer synchronization failed.',
                            icon: "error",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                        this.retailerIHeartJaneButtonText = "Synchronize";
                        this.retailerIHeartJaneButtonIconClass = "fa-solid fa-rotate";
                        this.retailerIHeartJaneButtonClass = "btn btn-primary mt-4";
                        this.retailerIHeartJaneButtonFlag = false;
                    }
                })
            }
            catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            }
        }
    }
}