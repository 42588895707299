export default {
    data() {
        return {
            url: this.$serverURL + this.$api.cms.sectionTwoTopURL,
            cmsData: {
                description: "",
                buttonText: "",
                buttonLink: "",
                video: ""
            },
            preview: {
                video: ""
            }
        }
    },
    async created() {
        document.title = "Jars - section Two Top CMS";
        this.getData();
    },
    methods: {
        async getData() {
            let response = await this.getCmsData(this.url);
            if (response.statusCode == 200) {
                this.cmsData = (response.data.length > 0) ? response.data[0] : {};
            }
            else {
                this.cmsData = {};
            }
        },
        handleUploadVideo(videoKey, previewVideoKey, ref, event) {
            this.cmsData[videoKey] = event.target.files[0];
            let input = this.$refs[ref];
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.preview[previewVideoKey] = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        async updateCms() {
            try {
                let fromData = new FormData();
                fromData.append('description', this.cmsData.description);
                fromData.append('buttonText', this.cmsData.buttonText);
                fromData.append('buttonLink', this.cmsData.buttonLink);
                fromData.append('video', this.cmsData.video);
                this.$swal.showLoading();
                let response = await this.createUpdateCMS(this.url, fromData);
                if (response.statusCode == 200 || response.statusCode == 201) {
                    this.$refs.video.value = null;
                    this.preview = {
                        video: ""
                    }
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                await this.getData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        }
    }
}