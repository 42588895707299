export default {
    data() {
        return {
            url: this.$serverURL + this.$api.cms.contactPageCmsURL,
            storeId: this.$route.query.id,
            storeName: this.$route.query.name,
            cmsData: {},
            center: { lat: 0, lng: 0 },
            markers: [
                {
                    position: {
                        lat: 0, lng: 0
                    }
                }
            ]
        }
    },
    async created() {
        document.title = "JARS - Contact Page CMS";
        this.getData();
    },
    methods: {
        async getData() {
            let response = await this.getCmsData(this.url + "?storeId=" + this.storeId);
            if (response.statusCode == 200) {
                this.cmsData = (response.data.length > 0) ? response.data[0] : {};
                if (this.cmsData.lat && this.cmsData.lng) {
                    this.center = {
                        lat: parseFloat(this.cmsData.lat),
                        lng: parseFloat(this.cmsData.lng)
                    };
                    this.markers = [
                        {
                            position: {
                                lat: parseFloat(this.cmsData.lat), lng: parseFloat(this.cmsData.lng)
                            }
                        }
                    ]
                }

            }
            else {
                this.cmsData = {};
            }
        },
        handleAddressLatLng(locationData) {
            let location = locationData.formatted_address;
            let locationSplit = location.split(',');
            if (locationSplit[0].trim() != locationData.name) {
                location = locationData.name + ', ' + location;
            }
            this.cmsData.lat = locationData.geometry.location.lat();
            this.cmsData.lng = locationData.geometry.location.lng();
            this.center = { lat: this.cmsData.lat, lng: this.cmsData.lng };
            this.markers = [{
                position: {
                    lat: this.cmsData.lat, lng: this.cmsData.lng
                }
            }]
            this.cmsData.location = location;
        },
        async updateCms() {
            try {
                let data = {
                    title: this.cmsData.title,
                    subTitle: this.cmsData.subTitle,
                    location: this.cmsData.location,
                    lat: this.cmsData.lat,
                    lng: this.cmsData.lng,
                    phone: this.cmsData.phone,
                    email: this.cmsData.email,
                    buttonText: this.cmsData.buttonText,
                    storeId: this.storeId || this.cmsData.storeId,
                    storeName: this.storeName || this.cmsData.storeName,
                    isDefault: this.cmsData.isDefault ? this.cmsData.isDefault : false
                };
                this.$swal.showLoading();
                let response = await this.createUpdateCMS(this.url, data);
                if (response.statusCode == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                await this.getData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally{
                this.$swal.hideLoading();
            }
        }
    }
}