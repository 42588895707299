<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Home</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item">
                                <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Home Page</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Home Component UI CMS</h5>
                    <hr />
                    <div class="form-body mt-4" v-if="dataLoaded">
                        <div class="row mb-3">
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Home Page Background Color:" v-model="cmsData.pageBackgroundColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="mt-5">
                                <h6>High Five Section</h6>
                            </div>
                            <hr/>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="High Five Section Background Color:" v-model="cmsData.highFiveSectionBackgroundColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="High Five Section Title Font Color:" v-model="cmsData.highFiveSectionTitleFontColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="High Five Section Subtitle Font Color:" v-model="cmsData.highFiveSectionSubtitleFontColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="High Five Section Button Background Color:" v-model="cmsData.highFiveSectionButtonBackgroundColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="High Five Section Button Font Color:" v-model="cmsData.highFiveSectionButtonFontColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="High Five Section Button Border Color:" v-model="cmsData.highFiveSectionButtonBorderColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="mt-5">
                                <h6>Shop By Price Section</h6>
                            </div>
                            <hr/>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Shop By Price Section Background Color:" v-model="cmsData.shopByPriceSectionBackgroundColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Shop By Price Section Title Font Color:" v-model="cmsData.shopByPriceSectionTitleFontColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Shop By Price Section Subtitle Font Color:" v-model="cmsData.shopByPriceSectionSubtitleFontColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Shop By Price Section Button Background Color:" v-model="cmsData.shopByPriceSectionButtonBackgroundColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Shop By Price Section Button Font Color:" v-model="cmsData.shopByPriceSectionButtonFontColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Shop By Price Section Button Border Color:" v-model="cmsData.shopByPriceSectionButtonBorderColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="mt-5">
                                <h6>New Drops Section</h6>
                            </div>
                            <hr/>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="New Drops Section Background Color:" v-model="cmsData.newDropsSectionBackgroundColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="New Drops Section Title Font Color:" v-model="cmsData.newDropsSectionTitleFontColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="New Drops Section Subtitle Font Color:" v-model="cmsData.newDropsSectionSubtitleFontColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="New Drops Section Button Font Color:" v-model="cmsData.newDropsSectionButtonFontColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="New Drops Section Mobile Button Background Color:" v-model="cmsData.newDropsSectionButtonBackgroundColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="New Drops Section Mobile Button Font Color:" v-model="cmsData.newDropsSectionButtonMobileFontColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="New Drops Section Mobile Button Border Color:" v-model="cmsData.newDropsSectionButtonBorderColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="mt-5">
                                <h6>Higher Self Section</h6>
                            </div>
                            <hr/>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Higher Self Section Background Color:" v-model="cmsData.higherSelfSectionBackgroundColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Higher Self Section Title Font Color:" v-model="cmsData.higherSelfSectionTitleFontColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Higher Self Section Subtitle Font Color:" v-model="cmsData.higherSelfSectionSubtitleFontColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Higher Self Section Button Font Color:" v-model="cmsData.higherSelfSectionButtonFontColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="mt-5">
                                <h6>Higher Self Section Cards</h6>
                            </div>
                            <hr/>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Higher Self Section Card One Background Color:" v-model="cmsData.higherSelfSectionCardOneBackgroundColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Higher Self Section Card One Title Font Color:" v-model="cmsData.higherSelfSectionCardOneTitleFontColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="col-md-3 mb-4">
                                <div class="input-group">
                                    <LvColorpicker label="Higher Self Section Card One Subtitle Font Color:" v-model="cmsData.higherSelfSectionCardOneSubtitleFontColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Higher Self Section Card Two Background Color:" v-model="cmsData.higherSelfSectionCardTwoBackgroundColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Higher Self Section Card Two Title Font Color:" v-model="cmsData.higherSelfSectionCardTwoTitleFontColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="col-md-3 mb-4">
                                <div class="input-group">
                                    <LvColorpicker label="Higher Self Section Card Two Subtitle Font Color:" v-model="cmsData.higherSelfSectionCardTwoSubtitleFontColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Higher Self Section Card Three Background Color:" v-model="cmsData.higherSelfSectionCardThreeBackgroundColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Higher Self Section Card Three Title Font Color:" v-model="cmsData.higherSelfSectionCardThreeTitleFontColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="col-md-3 mb-4">
                                <div class="input-group">
                                    <LvColorpicker label="Higher Self Section Card Three Subtitle Font Color:" v-model="cmsData.higherSelfSectionCardThreeSubtitleFontColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Higher Self Section Card Four Background Color:" v-model="cmsData.higherSelfSectionCardFourBackgroundColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Higher Self Section Card Four Title Font Color:" v-model="cmsData.higherSelfSectionCardFourTitleFontColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="col-md-3 mb-4">
                                <div class="input-group">
                                    <LvColorpicker label="Higher Self Section Card Four Subtitle Font Color:" v-model="cmsData.higherSelfSectionCardFourSubtitleFontColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="mt-5">
                                <h6>Higher Self Section Card Button</h6>
                            </div>
                            <hr/>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Higher Self Section Card Button Background Color:" v-model="cmsData.higherSelfSectionCardButtonBackgroundColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Higher Self Section Card Button Font Color:" v-model="cmsData.higherSelfSectionCardButtonFontColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Higher Self Section Card Button Border Color:" v-model="cmsData.higherSelfSectionCardButtonBorderColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Higher Self Section Card Button Hover Background Color:" v-model="cmsData.higherSelfSectionCardButtonHoverBackgroundColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Higher Self Section Card Button Hover Font Color:" v-model="cmsData.higherSelfSectionCardButtonHoverFontColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Higher Self Section Card Button Hover Border Color:" v-model="cmsData.higherSelfSectionCardButtonHoverBorderColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="mt-5">
                                <h6>Sunny Daze Section</h6>
                            </div>
                            <hr/>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Sunny Daze Section Background Color:" v-model="cmsData.sunnyDazeSectionBackgroundColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Sunny Daze Section Title Font Color:" v-model="cmsData.sunnyDazeSectionTitleFontColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Sunny Daze Section Subtitle Font Color:" v-model="cmsData.sunnyDazeSectionSubtitleFontColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Sunny Daze Section Button Font Color:" v-model="cmsData.sunnyDazeSectionButtonFontColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Sunny Daze Section Mobile Button Background Color:" v-model="cmsData.sunnyDazeSectionButtonBackgroundColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Sunny Daze Section Mobile Button Font Color:" v-model="cmsData.sunnyDazeSectionButtonMobileFontColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Sunny Daze Section Mobile Button Border Color:" v-model="cmsData.sunnyDazeSectionButtonBorderColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="mt-5">
                                <h6>Shop Our Inventory Section</h6>
                            </div>
                            <hr/>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Shop Our Inventory Section Background Color:" v-model="cmsData.shopOurInventorySectionBackgroundColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Shop Our Inventory Section Title Font Color:" v-model="cmsData.shopOurInventorySectionTitleFontColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Shop Our Inventory Section Subtitle Font Color:" v-model="cmsData.shopOurInventorySectionSubtitleFontColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="d-grid">
                                <div class="d-grid gap-2 d-md-flex justify-content-md-start"> 
                                    <button class="btn btn-primary" @click="update" type="button">Update</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/home-component-ui.js"></script>