export default {
    data() {
        return {
            email: null,
            password: null,
            inputType: "password",
            iconFlag: true
        }
    },
    created() {
        document.title = "JARS - Login";
    },
    methods: {
        login: function () {
            if (!this.email) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter e-mail!"
                });
            }
            if (!this.password) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter password!"
                });
            }
            else {
                let data = {
                    email: this.email,
                    password: this.password
                };
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.auth.loginURL,
                    data: data
                };
                this.$axios(config).then((response) => {
                    if (response.status == 201) {
                        localStorage.setItem("token", response.data.token);
                        localStorage.setItem("id", response.data.user.id);
                        localStorage.setItem("name", response.data.user.firstName + ' ' + response.data.user.lastName);
                        localStorage.setItem("email", response.data.user.email);
                        localStorage.setItem("phone", response.data.user.phone);
                        window.location.href = '/home';
                    }
                }).catch(error => {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                })
            }
        },
        changeInputType: function () {
            if (this.inputType == "password") {
                this.inputType = "text";
                this.iconFlag = false;
            }
            else if (this.inputType == "text") {
                this.inputType = "password";
                this.iconFlag = true;
            }
        }
    }
}