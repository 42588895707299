import { authHeader } from '../../../auth';

export default {
    data() {
        return {
            setURL: this.$serverURL + this.$api.weeklyAd.tagURL,
            tagName: '',
            dataList: [],
            singleData: {},
        }
    },
    async created() {
        document.title = "JARS - Weekly Ad Tags";
        this.dataList = await this.getDataList(this.setURL);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        validate: function () {
            if (!this.tagName) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter tag name!"
                });
                return false;
            }
            return true;
        },
        addEntity: async function () {
            if (this.validate() == true) {
                let token = localStorage.getItem('token');
                let config = {
                    method: "POST",
                    url: this.setURL,
                    data: { tagName: this.tagName },
                    headers: {
                        "Authorization": "Bearer " + token,
                        // "Content-Type": "multipart/form-data"
                    }
                };
                await this.$axios(config).then(async (response) => {
                    if (response.status == 201) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                    }
                    this.tagName = '';
                    this.dataList = await this.getDataList(this.setURL);
                    document.getElementById("closeButton").click();
                }).catch(error => {
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        },
        deleteEntity: function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.setURL + '/' + id,
                            headers: authHeader()
                        }
                        await this.deleteData(config);
                        this.dataList = await this.getDataList(this.setURL);
                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    }
                }
            })
        },
        editEntity: async function (data) {
            this.singleData = data;
        },
        updateValidation: function () {
            if (!this.singleData.tagName) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter tag name!"
                });
                return false;
            }
            return true;
        },
        updateEntity: async function () {
            if (this.updateValidation() == true) {
                let token = localStorage.getItem('token');
                const data = { tagName: this.singleData.tagName };
                let config = {
                    method: "PATCH",
                    url: this.setURL + '/' + this.singleData.id,
                    data,
                    headers: {
                        "Authorization": "Bearer " + token,
                        // "Content-Type": "multipart/form-data"
                    }
                };
                await this.$axios(config).then(async (response) => {
                    if (response.status == 200) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                    }
                    this.singleData = {};
                    this.dataList = await this.getDataList(this.setURL);
                    document.getElementById("buttonClose").click();
                }).catch(error => {
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        }
    }
}