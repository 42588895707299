export default {
    data() {
        return {
            url: this.$serverURL + this.$api.cms.dealsDropdownCmsURL,
            cmsData: {},
            previewImage: null,
            previewImage2: null,
        }
    },
    async created() {
        document.title = "JARS - Deals Dropdown";
        this.getData();
    },
    methods: {
        async getData() {
            let response = await this.getCmsData(this.url);
            if (response.statusCode == 200) {
                console.log("data", response.data);
                this.cmsData = (response.data.length > 0) ? response.data[0] : {};
                if (this.cmsData.showOpenNowButton == 1) {
                    this.cmsData.showOpenNowButton = true;
                } else {
                    this.cmsData.showOpenNowButton = false;
                }
                if (this.cmsData.openNowButtonFile) {
                    this.cmsData.hasFile = true;
                }
            }
            else {
                this.cmsData = {};
            }
            console.log(this.cmsData);
        },
        handleUpdateUploadFile(event) {
            this.cmsData.openNowButtonFile = event.target.files[0];
        },
        handleUploadFile(event) {
            this.cmsData.openNowButtonFile = event.target.files[0];
        },
        handleUpdateStatus(e) {
            this.cmsData.showOpenNowButton = e.target.checked;
        },
        onImageChange(fieldName) {
            const inputRef = this.$refs[fieldName];
            this.cmsData[fieldName] = inputRef.files[0];
      
            if (inputRef.files[0]) {
              const reader = new FileReader();
              reader.onload = (e) => {
                if (fieldName === "bannerOneImage") {
                  this.previewImage = e.target.result;
                } else if (fieldName === "bannerTwoImage") {
                  this.previewImage2 = e.target.result;
                }
              };
              reader.readAsDataURL(inputRef.files[0]);
            }
          },
        async update() {
            try {
                const formData = new FormData();
                formData.append('dealsCategoryTitle', this.cmsData.dealsCategoryTitle);
                formData.append('dealsCategoryButtonText', this.cmsData.dealsCategoryButtonText);
                formData.append('bannerOneImage', this.cmsData.bannerOneImage);
                formData.append('bannerOneButtonText', this.cmsData.bannerOneButtonText);
                formData.append('bannerOneButtonLink', this.cmsData.bannerOneButtonLink);
                formData.append('bannerTwoTitle', this.cmsData.bannerTwoTitle);
                formData.append('bannerTwoImage', this.cmsData.bannerTwoImage);
                formData.append("openNowButtonText", this.cmsData.openNowButtonText);
                formData.append("openNowButtonFile", this.cmsData.openNowButtonFile);
                formData.append("showOpenNowButton", this.cmsData.showOpenNowButton ? 1 : 0);
                formData.append('bannerTwoButtonText', this.cmsData.bannerTwoButtonText);
                formData.append('bannerTwoButtonLink', this.cmsData.bannerTwoButtonLink);
                formData.append('sectionTwoContentTwo', this.cmsData.sectionTwoContentTwo);
                formData.append('dealsTitle', this.cmsData.dealsTitle);
                this.$swal.showLoading();
                console.log(Object.fromEntries(formData));
                let response = await this.createUpdateCMS(this.url, formData);
                if (response.statusCode == 200 || response.statusCode == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                    console.log("response", response.data);
                }
                await this.getData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        }
    }
}